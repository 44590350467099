
/* globals AFRAME, digitalData, _satellite */
import React from 'react';

class Portal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['portal-animation']) {
            AFRAME.registerComponent('portal-animation', {
                schema: {
                    innerRadius: { type: "number" },
                    duration: { type: "number" }
                },

                init: function () {
                    this.foundHandller = this.foundHandller.bind(this);
                    this.el.sceneEl.addEventListener('xrimagefound', this.foundHandller)
                },

                remove: function () {
                    this.el.sceneEl.removeEventListener('xrimagefound', this.foundHandller)
                },

                foundHandller: function () {
                    const self = this;
                    setTimeout(() => {
                        self.el.setAttribute('animation__scaleIn', {
                            property: 'scale',
                            dur: self.data.duration,
                            from: '0.01 0.01 0.01',
                            to: self.data.innerRadius + ' ' + self.data.innerRadius + ' ' + self.data.innerRadius,
                            easing: 'easeOutElastic',
                        })
                    }, 200)
                }
            });

        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity>

                    {/* <a-ring
                        id="portal"
                        portal-animation={'innerRadius: ' + this.state.currentContent.innerRadius + '; duration: ' + this.state.currentContent.duration + ';'}
                        radius-inner="0.001"
                        radius-outer="1000"
                        xrextras-hider-material
                    ></a-ring> */}
                    <a-entity
                        gltf-model={'./assets/model/portalFrame.glb'}
                        portal-animation={'innerRadius: ' + this.state.currentContent.scale + '; duration: ' + this.state.currentContent.duration + ';'}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        rotation={'0 90 90'}
                        xrextras-hider-material
                        scale='0.01 0.01 0.01'>
                            <a-plane
                            width='1'
                            height='00.157'
                            position="-0.62 0.01 -0.7"
                            rotation='-90 0 -90'
                            scale='0.6 0.6 0.6'
                            material='shader:flat; side:double;'
                            src="./assets/img/Z_FE_03/Z_FE_03.jpg"
                            ></a-plane>
                        </a-entity>

                    <a-sky
                        portal-video={'urlVideo: ' + this.state.currentContent.URL}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        scale="0.015 0.015 -0.015"
                        material='shader: flat;'
                        radius='300'
                    ></a-sky>

                </a-entity>
            </a-entity>
        )
    }
}
export default Portal;
