/* globals AFRAME */
import React from 'react';

class ModelGLTF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent,
            textureIndex: props.textureIndex
        };
    }
    componentDidMount() {
        //console.log('Interactive 3D')
    }

    componentDidUpdate(prevProps) {
        //console.log('modelGLTFcustom componenDidUpdate Index selected:', prevProps.textureIndex);
        //var changeEntity = document.querySelector('#modelGLTF');
        //var thisMaterialModel = changeEntity.object3D.getObjectByName('EXT_CARPAINT');
        //console.log('componentDidUpdate Material ', thisMaterialModel);
        //console.log('componentDidUpdate Entity ID', changeEntity.id);
        //console.log('componentDidUpdate Entity ', changeEntity);
        //console.log('componentDidUpdate Entity object3D', changeEntity.object3D);
        //console.log('componentDidUpdate Entity MaterialModel', thisMaterialModel);
              //console.log('componentDidUpdate Entity MaterialModel.Material ', thisMaterialModel.material);
        //console.log('componentDidUpdate Entity Material ', changeEntity);
        //if (!prevProps.textureIndex){
        //  thisMaterialModel.material.color.set(0x1a525e);
        //}
        //var sphericalTester = document.querySelector('#ruralEnvMap');
        //console.log('Rural Road Cubemap ', sphericalTester);
    }

    render () {
        return (
            <a-entity id="entity_01" xrextras-named-image-target={'name: '+this.state.currentContent.targetName+';'}>
                <a-entity id="entity_02 " rotation={'90 0 0'}>
                    <a-entity xrextras-one-finger-rotate
                              gltf-model={this.state.currentContent.URL}
                              cube-env-map="path: ./assets/model/envMap/Rural-Road-Cube-Map_matt/; extension: png; roughness: 1; metalness: 0;"
                              position={ this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " 0"}
                              scale={this.state.currentContent.scale.x + "  "+ this.state.currentContent.scale.y +" "+ this.state.currentContent.scale.z}
                              id={'modelGLTF'} animation-mixer>
                    </a-entity>
                    <a-entity light="type: directional; color: #FFFFFF; intensity: 1" position="5 0 0.5" target="#modelGLTF"></a-entity>
                    <a-entity light="type: directional; color: #FFFFFF; intensity: 1" position="-5 0 0.5" target="#modelGLTF"></a-entity>
                    <a-entity light="type: directional; color: #FFFFFF; intensity: 1" position="0 5 0.5" target="#modelGLTF"></a-entity>
                </a-entity>
            </a-entity>
        )}
}
export default ModelGLTF;
