import React from 'react';
import './OverlayScreen.css';
import arrow from "../assets/img/arrow.png";

class OverlayScreen extends React.Component {

    render () {


        return (
            <div className={'OverlayScreen'}>
                <p>
                    Für das beste AR-Erlebnis legen Sie
                    <br />die Zeitungsseite auf eine flache Unterlage
                    <br /> und aktivieren Sie das automatische
                    <br /> Drehen des Bildschirms!
                </p>
                <p>
                    Um den Inhalt zu wechseln, nutzen
                    <br />Sie einfach den integrierten
                    <br />QR-Code-Scanner.
                </p>
                <button onClick={this.props.onClick}>
                    Ok
                    <img src={arrow} alt={'arrow'}/>
                </button>
                <p id='fine-print'>
                    Um Ladezeiten gering zu halten, wird eine stabile
                    <br />und ausreichend schnelle Internetverbindung benötigt.
                </p>
            </div>
        )}
}
export default OverlayScreen;