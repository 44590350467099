/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import { Color } from 'three';
import './registerComponent';

class ImageCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent,
            images: [],
            imageIndex: 0,
            currentAudio: null,
        };

        const imageURLs = this.state.currentContent.URL
        for (let i = 0; i < imageURLs.length; i++) {
            let tempIMG = document.createElement('img')
            this.state.images.push(tempIMG)
            tempIMG.src = imageURLs[i]

            
        }

    }
    componentDidMount() {
     this.btnPrev = document.getElementById('btnPrev');
     this.btnNext = document.getElementById('btnNext');
        const imgArray = this.state.currentContent.URL;
        let imageIndex = this.state.imageIndex;

    const imageElement = document.getElementById('carousel-image')
    imageElement.setAttribute('material', 'src',this.state.images[0])

        if(imageIndex === 0){
            this.btnPrev.object3D.visible = false;
        }
        if(imgArray.length === 1){
            this.btnPrev.object3D.visible = false;
            this.btnNext.object3D.visible = false;
        }

        let media = {id:'',title:'',type:'', subMediaID:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Galerie'
        if(this.state.currentContent.hasAudio) media.type = 'Galerie mit Ton'
        media.subMediaID = ''+imageIndex

        digitalData.media = media
        _satellite.track("Media Scanned")
    }

    goPrev = (event) => {
        if(this.state.currentAudio !== null) this.state.currentAudio.pause()
        event.stopPropagation();
        let imageIndex = this.state.imageIndex;

        if (imageIndex > 0) {
            imageIndex --;
            this.setState({imageIndex});

            let media = {id:'',title:'',type:'', subMediaID:''}
            media.id = this.state.currentContent.targetName
            media.title = this.state.currentContent.targetName
            media.type = 'Galerie'
            media.subMediaID = ''+imageIndex

            digitalData.media = media
            _satellite.track("Media - Previous Gallery Image Loaded")

            const imageElement = document.getElementById('carousel-image')
            imageElement.setAttribute('material', 'src',this.state.images[imageIndex])
        }
        if(imageIndex === 0){
            this.btnPrev.object3D.visible = false;
            this.btnNext.object3D.visible = true;
        }else{
            this.btnPrev.object3D.visible = true;
            this.btnNext.object3D.visible = true;
        }
    };
    goNext = (event) => {
        if(this.state.currentAudio !== null) this.state.currentAudio.pause()
        event.stopPropagation();
        const imgArray = this.state.currentContent.URL;
        let imageIndex = this.state.imageIndex;

        if (imageIndex < imgArray.length-1) {
            imageIndex ++;
            this.setState({imageIndex});

            let media = {id:'',title:'',type:'', subMediaID:''}
            media.id = this.state.currentContent.targetName
            media.title = this.state.currentContent.targetName
            media.type = 'Galerie'
            media.subMediaID = ''+imageIndex

            digitalData.media = media
            _satellite.track("Media - Next Gallery Image Loaded")

            const imageElement = document.getElementById('carousel-image')
            imageElement.setAttribute('material', 'src',this.state.images[imageIndex])
        }
        if(imageIndex === imgArray.length-1){
            this.btnNext.object3D.visible = false;
            this.btnPrev.object3D.visible = true;
        }else{
            this.btnPrev.object3D.visible = true;
            this.btnNext.object3D.visible = true;
        }

        
    };
    playAudio = (event) => {

        var currentAudio = this.state.currentAudio
        if(this.state.currentAudio === null)
        {
            const audiofile = document.getElementById('audio-file')
            audiofile.src = this.state.currentContent.audioURL[this.state.imageIndex]
            currentAudio = audiofile
            this.setState({currentAudio})
        }
        else if(this.state.currentAudio.paused)
        {
            const audiofile = document.getElementById('audio-file')
            audiofile.src = this.state.currentContent.audioURL[this.state.imageIndex]
            currentAudio = audiofile
            this.setState({currentAudio})
        }

        if(currentAudio.paused)
        {
            currentAudio.play()
            _satellite.track("Audio-started")
        }
        else
        {
            currentAudio.pause()
        }
    };

    timer  = () => {
        var timerElement = document.getElementById('timerText')
        var length = 0;
        const timeString = () => {
            if(timerElement === null)
            {
                timerElement = document.getElementById('timerText')
            }
            else if(!this.state.currentAudio.paused)
            {
                if(!isNaN(this.state.currentAudio.duration)) length = this.formatTimeString(Math.floor(this.state.currentAudio.duration))
                let tempString = this.formatTimeString(Math.floor(this.state.currentAudio.currentTime))
                timerElement.setAttribute('text', 'value:' + tempString + ' / ' + length + '; align: center; width: 1;')
            }
        }
        setInterval(timeString, 250);
    }

    formatTimeString = (timeInSec) => {
        let minutes = Math.floor(timeInSec/60)
        let seconds = timeInSec % 60

        var timeString = '00:'
        if(minutes < 10) {
            timeString += '0' + minutes + ':'
        }
        else {
            timeString += minutes + ':'
        } 
        if(seconds < 10) {
            timeString += '0' + seconds
        }
        else {
            timeString += seconds
        }

        return timeString
    }



    render () {
        return (
            <a-entity>
                <a-entity id={"carousel-image"} geometry={"primitive: plane; height: "+this.state.currentContent.size[this.state.imageIndex].h+"; width: "+this.state.currentContent.size[this.state.imageIndex].w+";"}
                          position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " 0"}
                          material={"transparent: true;"}>
                </a-entity>

                {this.state.currentContent.text[this.state.imageIndex] &&
                <a-entity geometry={"primitive: plane; width: "+this.state.currentContent.size[this.state.imageIndex].w+"; height: auto"}
                          material={"color: #110a35"}
                          position={this.state.currentContent.position.x +' -'+ (this.state.currentContent.size[this.state.imageIndex].h/2)+0.05 +' 0.007'}
                          text={'value:'+this.state.currentContent.text[this.state.imageIndex]+'; color: white; width: '+(this.state.currentContent.size[this.state.imageIndex].w-0.08)+'; height: auto'}>
                </a-entity>}

                <a-entity position={(-0.61 + this.state.currentContent.position.x) + ' ' +this.state.currentContent.position.y + ' 0'}
                           id={'btnPrev'} onClick={this.goPrev} button-animation>
                    <a-image class={'cantap'} src={"#prev-btn"} width={'0.2'} height={"0.2"} position={'0 0 0.003'}></a-image>
                </a-entity>
                <a-entity position={(0.61 + this.state.currentContent.position.x) + ' ' + this.state.currentContent.position.y + ' 0'}
                           id={'btnNext'} onClick={this.goNext} button-animation={'scale: 0.2; time: 100'}>
                    <a-image class={'cantap'} src={"#next-btn"} width={'0.2'} height={"0.2"} position={'0 0 0.003'}></a-image>
                </a-entity>
                <a-entity has-audio={'hasAudio: ' + this.state.currentContent.hasAudio} position={'0.61 '+(this.state.currentContent.position.y - 0.25 ) +' 0'}
                            id={'btnSound'} onClick={this.playAudio} button-animation>
                    <a-image class={'cantap'} src={"#audio-btn"} width={'0.2'} height={"0.2"} position={'0 0 0.003'}></a-image>
                </a-entity>
                {this.state.currentAudio !== null && !this.state.currentAudio.paused &&
                <a-entity position={'0.61 '+(this.state.currentContent.position.y - 0.45 ) +' 0'}>
                    <a-entity id={'timerText'} position={'0 0 0.003'}></a-entity>
                    {this.timer()}
                </a-entity>}
            </a-entity>
        )}
}
export default ImageCarousel;