/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
class DATEV extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Anzeige mit Interaktion'

        digitalData.media = media
        _satellite.track("Media Scanned")

        function playAnimation(object, time, from, to) {
            var t = {
                'rot': 0,
                'moveZ': 0,
            }
            var isAlreadyPlaying = false
            for (let i = 0; i < AFRAME.ANIME.running.length; i++) {
                if (AFRAME.ANIME.running[i].animatables[0].target.name) {
                    isAlreadyPlaying = true
                    break
                }
            }
            if (!isAlreadyPlaying) {
                var objectRot = object.object3D.rotation.clone()
                var isFlipped = false
                AFRAME.ANIME({
                    targets: t,
                    rot: Math.PI,
                    duration: time,
                    delay: 50,
                    easing: 'easeInOutSine',
                    update: function () {
                        object.object3D.rotation.y = objectRot.y + t.rot
                        if(t.rot >= Math.PI/2 && !isFlipped){
                            isFlipped = true
                            if(object.getAttribute('src') === from) {
                                object.setAttribute('src', to)
                                // object.setAttribute('material', 'shader: flat; transparent: true; side: double; repeat: -1 1'); 
                            }
                            else {
                                object.setAttribute('src', from);
                            }
                        }
                    }
                })
                var objectPos = object.object3D.position.clone()
                AFRAME.ANIME({
                    targets: t,
                    moveZ: 0.1,
                    duration: time,
                    loop: 2,
                    direction: 'alternate',
                    easing: 'easeInOutSine',
                    update: function () {
                        object.object3D.position.z = objectPos.z + t.moveZ
                    }
                })
            }
        }

        if (!AFRAME.components['flip-card']) {
            AFRAME.registerComponent('flip-card', {
                schema: {
                    time: {
                        type: "number",
                        default: 500
                    },
                    flipFrom: {
                        type: "string",
                        default: ''
                    },
                    flipTo: {
                        type: "string",
                        default: '',
                    }
                },
                init() {
                    this.el.classList.add("cantap")        
                    const flipAnimation = () => {
                        playAnimation(this.el, this.data.time, this.data.flipFrom, this.data.flipTo)
                    }
            
                    this.el.addEventListener('click', flipAnimation)
                }
            });
        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity>

                    <a-entity
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " 0"}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.string}>
                        <a-entity 
                            id={'cover'}
                            geometry={"primitive: plane; height: 1; width: 1;"}
                            position={'0 0 0'} 
                            material={"shader: flat; src: " + this.state.currentContent.cover + "; transparent: true"}
                            scale="1 1 1">
                        </a-entity>

                        <a-image 
                            id={'weniger-resssourcen'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[0].back + ";"}
                            width={'1'} height={'1'}
                            position={'0.37 0.07 0.003'} 
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>
                        <a-image 
                            id={'ressourcen-effizient'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[1].front + "; flipTo: " + this.state.currentContent.flipcards[1].back + ";"}
                            width={'1'} height={'1'}
                            position={'-0.2 -0.25 0.003'} 
                            src={this.state.currentContent.flipcards[1].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>
                        <a-image 
                            id={'co-bilanz'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[2].front + "; flipTo: " + this.state.currentContent.flipcards[2].back + ";"}
                            width={'1'} height={'1'}
                            position={'-0.063 0.22 0.003'} 
                            src={this.state.currentContent.flipcards[2].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>
                        <a-image 
                            id={'stromverbrauch'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[3].front + "; flipTo: " + this.state.currentContent.flipcards[3].back + ";"}
                            width={'1'} height={'1'}
                            position={'0.28 -0.21 0.003'} 
                            src={this.state.currentContent.flipcards[3].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>
                        <a-image 
                            id={'nachhaltige-geschaeftsmodelle'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[4].front + "; flipTo: " + this.state.currentContent.flipcards[4].back + ";"}
                            width={'1'} height={'1'}
                            position={'0.02 -0.23 0.003'} 
                            src={this.state.currentContent.flipcards[4].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>
                        <a-image 
                            id={'mitarbeitende'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[5].front + "; flipTo: " + this.state.currentContent.flipcards[5].back + ";"}
                            width={'1'} height={'1'}
                            position={'0.155 0.14 0.003'} 
                            src={this.state.currentContent.flipcards[5].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.22 0.22 0.22">
                        </a-image>

                        <a-image 
                            id={'mitarbeitende'}
                            flip-card={"time: 500; flipFrom: " + this.state.currentContent.flipcards[6].front + "; flipTo: " + this.state.currentContent.flipcards[6].back + ";"}
                            width={'1'} height={'1'}
                            position={'0 -0.8 0.001'} 
                            src={this.state.currentContent.flipcards[6].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: false;"}
                            scale="0.9 0.9 0.9">
                        </a-image>

                    </a-entity>
                </a-entity>
            </a-entity>
        )
    }
}
export default DATEV;
