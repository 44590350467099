import { qrcode } from './jsqrcode/src/qrcode'

var currentQRLink = null

// Define a custom pipeline module. This module scans the camera feed for qr codes, and makes the
// result available to other modules in onUpdate. It requires that the CameraPixelArray module is
// installed and configured to provide a luminance (black and white) image.
const qrprocessPipelineModule = () => ({
  name: 'qrprocess',
  onProcessCpu: ({ processGpuResult }) => {
    // Check whether there is any data ready to process.
    if (!processGpuResult.camerapixelarray || !processGpuResult.camerapixelarray.pixels) {
      return { found: false }
    }
    try {
      // Set input variables on the global qrcode object before calling qrcode.process().
      const { rows, cols } = processGpuResult.camerapixelarray
      qrcode.width = cols
      qrcode.height = rows
      qrcode.grayscale = () => processGpuResult.camerapixelarray.pixels
      const res = qrcode.process()  // Scan the image for a QR code.
      res.points = res.points.map(
        ({ x: x_, y: y_ }) => ({ x: x_ / (cols - 1), y: y_ / (rows - 1) })
      )
      return res
    } catch (e) {
      return { found: false }  // jsqrcode throws errors when qr codes are not found in an image.
    }
  },
})
// Define a custom pipeline module. This module updates UI elements with the result of the QR code
// scanning, and navigates to the found url on any tap to the screen.
const qrdisplayPipelineModule = () => {
  return {
    name: 'qrdisplay',
    onUpdate: ({ processCpuResult }) => {
      if (!processCpuResult.qrprocess) {
        return
      }
      const t = processCpuResult.qrprocess
      const { found, foundText, points } = t
      // Toggle display text based on whether a qrcode result was found.
      if (found) {
        currentQRLink = foundText
      } else {
      }
    },
  }
}

const getQrURL = () => {
  const tempURL = currentQRLink
  currentQRLink = null
  return tempURL
}

export { qrprocessPipelineModule, qrdisplayPipelineModule, getQrURL }