/* globals AFRAME, XR8, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three'

class SolarSail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['solar-sail']) {
            AFRAME.registerComponent('solar-sail', {
                schema: {
                    distanceBetween: { type: 'number', default: 0.8 },
                    dragDelay: { default: 150 },
                },
                init: function () {
                    this.camera = document.getElementById('camera');
                    this.earth = document.getElementById('earth');
                    this.sun = document.getElementById('sun');

                    // this.el.object3D.position.lerpVectors(this.earth.position, this.sun.position, this.data.distanceBetween);

                    this.internalState = {
                        fingerDown: false,
                        dragging: false,
                        startDragTimeout: null,
                        raycaster: new THREE.Raycaster(),
                    }


                    this.fingerDown = this.fingerDown.bind(this);
                    this.startDrag = this.startDrag.bind(this);
                    this.fingerMove = this.fingerMove.bind(this);
                    this.fingerUp = this.fingerUp.bind(this);

                    this.earth.addEventListener('mousedown', this.fingerDown);
                    this.el.sceneEl.addEventListener('onefingermove', this.fingerMove);
                    this.el.sceneEl.addEventListener('onefingerend', this.fingerUp);

                },

                tick: function () {

                    let sunWorldPosition = new THREE.Vector3();
                    this.sun.object3D.getWorldPosition(sunWorldPosition);

                    let earthWorldPosition = new THREE.Vector3();
                    this.earth.object3D.getWorldPosition(earthWorldPosition);

                    const distance = sunWorldPosition.distanceTo(earthWorldPosition);

                    if (this.internalState.dragging) {

                        if (this.internalState.positionRaw) {
                            if(!this.internalState.distance) this.internalState.distance = distance
                            const screenPositionX = this.internalState.positionRaw.x / document.body.clientWidth * 2 - 1;
                            const screenPositionY = this.internalState.positionRaw.y / document.body.clientHeight * 2 - 1;
                            const screenPosition = new THREE.Vector2(screenPositionX, -screenPositionY);

                            this.threeCamera = this.threeCamera || this.camera.getObject3D('camera');

                            this.internalState.raycaster.setFromCamera(screenPosition, this.threeCamera);
                            const intersections = this.internalState.raycaster.intersectObject(this.earth.object3D, true);
                            if (intersections.length > 0) {
                                const intersection = intersections[0];
                                if(!this.internalState.rayDistance) {
                                    this.internalState.rayDistance = intersection.distance;
                                    _satellite.track('content-clicked');
                                }
                            }
                            if (this.internalState.rayDistance) {

                                const newPosition = this.internalState.raycaster.ray.origin.clone().addScaledVector(
                                    this.internalState.raycaster.ray.direction, this.internalState.rayDistance
                                )

                                let direction = new THREE.Vector3();
                                direction.subVectors(newPosition, sunWorldPosition).normalize();
                                this.internalState.newPosition = sunWorldPosition.clone().addScaledVector(direction.normalize(), this.internalState.distance);
                                this.internalState.newPosition = this.el.object3D.parent.worldToLocal(this.internalState.newPosition);
                            }
                            // this.internalState.positionRaw = null;
                        }
                    }
                    if (this.internalState.newPosition) {
                        this.earth.object3D.position.lerp(this.internalState.newPosition, 0.1);
                        // this.earth.object3D.lookAt(sunWorldPosition);
                    }

                    let localDirection = new THREE.Vector3();
                    localDirection.subVectors(this.earth.object3D.position, this.sun.object3D.position).normalize();
                    let localDistance = this.sun.object3D.position.distanceTo(this.earth.object3D.position);
                    this.el.object3D.position.lerp(this.sun.object3D.position.clone().addScaledVector(localDirection, localDistance * this.data.distanceBetween), 0.1);
                    this.el.object3D.lookAt(earthWorldPosition);

                },

                fingerDown(event) {
                    this.internalState.fingerDown = true;
                    this.internalState.startDragTimeout = setTimeout(this.startDrag, this.data.dragDelay);
                    this.internalState.positionRaw = event.detail.positionRaw;
                },
                startDrag(event) {
                    if (!this.internalState.fingerDown) {
                        return;
                    }
                    this.internalState.dragging = true;
                },
                fingerMove(event) {
                    this.internalState.positionRaw = event.detail.positionRaw;
                },
                fingerUp(event) {
                    this.internalState.newPosition = null;
                    this.internalState.fingerDown = false;
                    this.internalState.rayDistance = null;
                    clearTimeout(this.internalState.startDragTimeout);

                    this.internalState.positionRaw = null;
                    this.internalState.dragging = false;
                }
            });

        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'} id={'solar-sail-scene'} visible={'false'}>
                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.models[0].URL}
                        position={this.state.currentContent.models[0].position.x + "  " + this.state.currentContent.models[0].position.y + " " + this.state.currentContent.models[0].position.z}
                        scale={this.state.currentContent.scale.x * this.state.currentContent.models[0].scale + "  " + this.state.currentContent.scale.y * this.state.currentContent.models[0].scale + " " + this.state.currentContent.scale.z * this.state.currentContent.models[0].scale}
                        rotation={this.state.currentContent.models[0].rotation.x + " " + this.state.currentContent.models[0].rotation.y + " " + this.state.currentContent.models[0].rotation.z}
                        id={'earth'} shadow class={'cantap'} model-loading={"unhide: solar-sail-scene;"}>
                    </a-entity>
                    <a-entity
                        gltf-model={this.state.currentContent.models[1].URL}
                        position={this.state.currentContent.models[1].position.x + "  " + this.state.currentContent.models[1].position.y + " " + this.state.currentContent.models[1].position.z}
                        scale={this.state.currentContent.scale.x * this.state.currentContent.models[1].scale + "  " + this.state.currentContent.scale.y * this.state.currentContent.models[1].scale + " " + this.state.currentContent.scale.z * this.state.currentContent.models[1].scale}
                        rotation={this.state.currentContent.models[1].rotation.x + " " + this.state.currentContent.models[1].rotation.y + " " + this.state.currentContent.models[1].rotation.z}
                        id={'sun'} class={'cantap'}>
                    </a-entity>
                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.models[2].URL}
                        position={this.state.currentContent.models[2].position.x + "  " + this.state.currentContent.models[2].position.y + " " + this.state.currentContent.models[2].position.z}
                        scale={this.state.currentContent.scale.x * this.state.currentContent.models[2].scale + "  " + this.state.currentContent.scale.y * this.state.currentContent.models[2].scale + " " + this.state.currentContent.scale.z * this.state.currentContent.models[2].scale}
                        rotation={this.state.currentContent.models[2].rotation.x + " " + this.state.currentContent.models[2].rotation.y + " " + this.state.currentContent.models[2].rotation.z}
                        id={'solar-sail'} shadow="receive: false" solar-sail={'distanceBetween: 0.7'} class={'cantap'}>
                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
                <a-image
                    id={'loading-spinner'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x + ' ' + this.state.currentContent.position.y + ' 0.003'}
                    visible={'true'}>
                </a-image>
            </a-entity>
        )
    }
}
export default SolarSail;
