/* globals XR8, glContextConfig, content */

import React from 'react';
import './App.css';
import Cookie from "./components/Cookie";
import LookingForTarget from "./components/LookingForTarget";
import ARScene from "./components/ARScene";
import QrCodeBtn from "./components/QrCodeBtn";
import QrCodeBtnBack from "./components/QrCodeBtnBack";
import QrCodeScanner from "./components/QrCodeScanner";
import { getTargetName, loadTarget } from "./common/getTargetName";
import Footer from "./components/Footer";
import Logo from "./components/Logo";
import OverlayScreen from "./components/OverlayScreen";
import EXTERNAL_LINK from "./components/external-link";
import ANZ_DHL from './components/customPage/ANZ_DHL';
import { isMobile, isBrowser } from 'react-device-detect';

import RotateOverlay from './components/RotateOverlay';
import InteractiveOverlay from './components/InteractiveOverlay';

import { qrprocessPipelineModule, qrdisplayPipelineModule, getQrURL } from './common/qrreader'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qrCodeBtn: false,
            userUI: false,
            overlayScreen: false,
            contentType: '',
            currentContent: {},
            playVideoWithPoster: false,
            textureIndex: 0,
            cookieWindow: true,
            customPageOn: true,
            lookingForTarget: true
        }
    }

    scanQrCode = () => {
        let v = document.getElementById('video-mp4');
        let a = document.getElementById('audio-file')
        //let mainObject3D = document.getElementById('mainObject3D').object3D;

        if (this.state.qrCodeBtn) {

            this.setState({
                qrCodeBtn: false,
                customPageOn: true
            });
            console.log('qrCodeBtn off');
        } else {
            if (v) {
                v.pause();
            }
            if (a) {
                a.pause();
            }
            this.setState({
                qrCodeBtn: true,
                customPageOn: false
            });
        }
    };
    startAR = () => {
        let targetName = getTargetName('target', window.location.href);

        console.log('---- current target: ' + targetName + ' ----')
        let currentContent = content[targetName];
        let contentType = currentContent.type;

        loadTarget(targetName);

        this.setState({
            overlayScreen: false,
            currentContent: currentContent,
            contentType: contentType
        });

        XR8.resume();
        const startListen = () => {
            XR8.addCameraPipelineModules([
                XR8.CameraPixelArray.pipelineModule({ luminance: true, maxDimension: 640 }),  // Provides pixels.
                qrprocessPipelineModule(),
                qrdisplayPipelineModule()
            ])
        }
        XR8 ? startListen() : window.addEventListener('xrloaded', startListen)
    };

    componentDidMount() {
        window.addEventListener("xrloaded", (event) => {
            XR8.pause();
        });
        this.renderContent();

        window.addEventListener('xrimagefound', () => {
            this.setState({ lookingForTarget: false });
        });
        window.addEventListener('xrimagelost', () => {
            this.setState({ lookingForTarget: true });
        });
    }

    onContentTypeChange = (contentType, currentContent) => {
        this.setState({
            contentType,
            currentContent
        });

        if (contentType === 'videoWithSound' || contentType === 'videoWithSoundChromakey') {
            this.setState({ playVideoWithPoster: true });
        }
    };
    onTextureIndexChange = (textureIndex) => {
        this.setState({ textureIndex });

        setTimeout(() => {
            console.log('App.js textureIndex ', this.state.textureIndex)
        }, 10);
    };
    onCookieChange = () => {
        this.setState({
            cookieWindow: false
        });
        setTimeout(() => {
            this.setState({
                overlayScreen: true,
                userUI: true
            });
        }, 1000)
    };
    renderContent = () => {
        if (isBrowser) {
            this.setState({
                cookieWindow: false
            });
        }
    };

    getCurrentContent = () => {
        return this.state.currentContent
    }

    getContentType = () => {
        return this.state.contentType
    }

    getPlayVideoWithPoster = () => {
        return this.state.playVideoWithPoster
    }

    getTextureIndex = () => {
        return this.state.textureIndex
    }

    render() {
        return (
            <div className="App">
                {isMobile && this.state.cookieWindow && <Cookie
                    onCookieChange={this.onCookieChange} />}
                <Logo />
                {this.state.overlayScreen && <OverlayScreen onClick={this.startAR} />}

                {!this.state.cookieWindow && !this.state.overlayScreen && !this.state.qrCodeBtn && this.state.lookingForTarget &&
                    <LookingForTarget currentContent={this.state.currentContent} />}

                {!this.state.cookieWindow &&
                    <ARScene currentContent={this.getCurrentContent}
                        contentType={this.getContentType}
                        playVideoWithPoster={this.getPlayVideoWithPoster}
                        textureIndex={this.getTextureIndex}
                        onChange={this.scanQrCode}
                        onContentTypeChange={this.onContentTypeChange} />
                }


                {this.state.qrCodeBtn && <QrCodeScanner onChange={this.scanQrCode}
                    onContentTypeChange={this.onContentTypeChange} />}

                {!this.state.cookieWindow && this.state.userUI && !this.state.qrCodeBtn &&
                    <QrCodeBtn onClick={this.scanQrCode} />}
                {this.state.userUI && this.state.qrCodeBtn && !this.state.customPageOn &&
                    <QrCodeBtnBack onClick={this.scanQrCode} />}

                <Footer />

                {/* {this.state.currentContent !== null && console.log('type of: ' + typeof(this.state.currentContent.hasExternalLink))} */}
                {!this.state.cookieWindow && !this.state.qrCodeBtn && this.state.currentContent !== null && this.state.currentContent.hasExternalLink === true &&
                    <EXTERNAL_LINK currentContent={this.state.currentContent} />}

                {!this.state.cookieWindow && this.state.currentContent !== null && this.state.currentContent.targetName === 'ANZ_DHL_01' &&
                    <ANZ_DHL />}

                {!this.state.cookieWindow && this.state.currentContent !== null && !this.state.qrCodeBtn && (
                    this.state.currentContent.type === 'modelGLTF' || 
                    this.state.currentContent.type === 'globe' || 
                    this.state.currentContent.type === 'animals' || 
                    this.state.currentContent.type === '360Portal'
                    ) &&
                    <RotateOverlay />}

                {!this.state.cookieWindow && this.state.currentContent !== null && !this.state.qrCodeBtn && (
                    this.state.currentContent.type === 'soccer' || 
                    this.state.currentContent.type === 'ruleOneTen' || 
                    this.state.currentContent.type === 'zalando' || 
                    this.state.currentContent.type === 'synthesizer' || 
                    this.state.currentContent.type === 'buildingblocks' || 
                    this.state.currentContent.type === 'map' || 
                    this.state.currentContent.type === 'frontPage' || 
                    this.state.currentContent.type === 'solarsail'
                    ) &&
                    <InteractiveOverlay />}

                {/* {!this.state.cookieWindow && this.state.currentContent.targetName === 'ANZ_VW' && this.state.customPageOn &&
                <ANZ_VW currentContent={this.state.currentContent}
                        onTextureIndexChange={this.onTextureIndexChange}/>}

                {!this.state.cookieWindow && this.state.currentContent.targetName === 'ANZ_DZ' && this.state.customPageOn &&
                <ANZ_DZ/>} */}


            </div>
        );
    }

}

export default App;
