/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
import { Pathfinding, PathfindingHelper } from 'three-pathfinding';

class Zalando extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")
        
        if (!AFRAME.components['nav-mesh']) {
            AFRAME.registerComponent('nav-mesh', {
                schema: {
                    agent: { type: "string", default: "bot" },
                    zone: { type: "string", default: "zone1" },
                    speed: { type: "number", default: 5 }
                },

                init: function () {
                    this.clock = new THREE.Clock();
                    this.navMeshEl = document.getElementById('navmesh');
                    this.agentEl = document.getElementById(this.data.agent);
                    this.zoneModelEl = document.getElementById(this.data.zone);

                    this.navMeshEl.addEventListener('model-loaded', () => {
                        const obj = this.navMeshEl.getObject3D('mesh');
                        obj.traverse(child => {
                            if (child.isMesh) {
                                this.pathfinding = new Pathfinding();
                                this.navMesh = child;
                                this.pathfinding.setZoneData(this.data.zone, Pathfinding.createZone(child.geometry));

                                this.pathfindinghelper = new PathfindingHelper()
                                this.el.object3D.add(this.pathfindinghelper);

                                this.clickHandler = this.clickHandler.bind(this);
                                this.zoneModelEl.addEventListener('mousedown', this.clickHandler);
                            }
                        })
                    })
                },

                clickHandler: function (e) {
                    if (e.detail.intersection) {
                        let point = e.detail.intersection.point.clone();
                        point = this.el.object3D.worldToLocal(point);
                        
                        const agentPos = this.agentEl.object3D.position;
                        this.groupID = this.pathfinding.getGroup(this.data.zone, agentPos);
                        const closestIntersectionNode = this.pathfinding.getClosestNode(point, this.data.zone, this.groupID); 
                        const closestNode = this.pathfinding.getClosestNode(agentPos, this.data.zone, this.groupID);
                        if (this.navPath && this.navPath.length > 0) {
                            const endPointPath = this.navPath[this.navPath.length - 1];
                            const pathToAdd = this.pathfinding.findPath(endPointPath, closestIntersectionNode.centroid, this.data.zone, this.groupID);
                            this.navPath = this.navPath.concat(pathToAdd);
                        }
                        else {
                            this.navPath = this.pathfinding.findPath(closestNode.centroid, closestIntersectionNode.centroid, this.data.zone, this.groupID);
                        }
                        if (this.navPath) {
                            this.pathfindinghelper.reset();
                            this.pathfindinghelper.setPlayerPosition(agentPos);
                            this.pathfindinghelper.setTargetPosition(closestIntersectionNode.centroid);
                            this.pathfindinghelper.setPath(this.navPath);
                        }
                        _satellite.track('content-clicked');
                    }

                },

                moveAgent: function (deltaT) {
                    if (!this.navPath) {
                        return;
                    }
                    else if (this.navPath.length <= 0) {
                        this.pathfindinghelper.reset();
                        return;
                    }
                    const targetPosition = this.navPath[0];
                    const direction = targetPosition.clone().sub(this.agentEl.object3D.position);
                    if (direction.lengthSq() > 0.1 * 0.1) {
                        direction.normalize()
                        this.agentEl.object3D.rotation.y = Math.atan2(direction.x, direction.z) + Math.PI;
                        const newPos = this.agentEl.object3D.position.clone().add(direction.multiplyScalar(deltaT * this.data.speed));
                        this.agentEl.object3D.position.lerp(newPos, 0.3);

                    }
                    else {
                        this.navPath.shift();
                    }
                },

                remove: function () {
                    this.zoneModelEl.removeEventListener('mousedown', this.clickHandler);
                },

                tick: function (time, timeDelta) {
                    this.moveAgent(timeDelta * 0.001);
                }
            });

        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity
                    rotation={'0 -90 -90'}
                    nav-mesh={'speed: ' + this.state.currentContent.speed + ';'}
                    position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                    scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                    id={'zalando-scene'} visible={'false'}>
                    <a-entity basic-mat={'setBasic: ' + true}
                        gltf-model={this.state.currentContent.URLs[0]}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'navmesh'} visible="false">
                    </a-entity>

                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.URLs[1]}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'zone1'} class={'cantap'} model-loading={'unhide: zalando-scene;'}>
                    </a-entity>

                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.URLs[2]}
                        position={this.state.currentContent.botPosition.x + "  " + this.state.currentContent.botPosition.y + " " + this.state.currentContent.botPosition.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'bot'}>
                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #000000; transparent: true; opacity: 0;"}
                        position={(this.state.currentContent.position.x + this.state.currentContent.textOffset.x) + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset.y) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: #DBDCDE; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
                <a-image
                    id={'loading-spinner'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x + ' ' + this.state.currentContent.position.y + ' 0.003'}
                    visible={'true'}>
                </a-image>
            </a-entity>
        )
    }
}
export default Zalando;
