/* globals content */

import React from 'react';
import './RotateOverlay.css';




class RotateOverlay extends React.Component {

    constructor(props) {
        super(props);

    }
    handleError = err => {
        console.error(err)
    }

    render() {
        return (
            <div id='RotateOverlay' className={'RotateOverlay'}>
                <img src={'./assets/img/RotateIconWhite.svg'} alt={'rotate-icon'}/>
            </div>
        )
    }
}

export default RotateOverlay;