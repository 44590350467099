/* globals AFRAME, content */
import React from 'react';
import * as THREE from 'three';
import ImageCarousel from './a-frame/ImageCarousel';
import ImageSwipe from './a-frame/ImageSwipe';
import VideoWithSound from './a-frame/video';
// import VideoNoSound from "./a-frame/videoNoSound";
// import VideoNoSoundChromakey from "./a-frame/videoNoSoundChromakey";
// import {VideoNoSoundChromakeyAsset} from './a-frame/videoNoSoundChromakeyAsset';
// import {VideoWithSoundChromakeyAsset} from './a-frame/videoWithSoundChromakeyAsset';
import ModelGLTF from "./a-frame/modelGLTF";
import ModelGLTFcustom from "./a-frame/modelGLTFcustom";
import './a-frame/registerComponent';

import { getQrURL } from '../common/qrreader';
import { getTargetName, loadTarget } from "../common/getTargetName";
import { getCurrentDetailName } from './a-frame/registerComponent';
// import content from "../common/content";

import DATEV from './customPage/ANZ_DATEV';
import Globe from './customPage/Globe';
import CardSwipe from './customPage/CardSwipe';
import Soccer from './customPage/Soccer';
import Portal from './customPage/Portal';
import Animals from './customPage/Animals';

// -- 20223--
import Synthesizer from "./customPage/Synthesizer";
import SolarSail from './customPage/SolarSail';
import BuildingBlocks from './customPage/BuildingBlocks';
import Map from './customPage/Map';
import Zalando from './customPage/Zalando';
import RuleOneTen from './customPage/RuleOneTen';
import ThreeSixtyPortal from './customPage/360Portal';
import FrontPage from './customPage/FrontPage';
// -- eof 2023 --
import { MeshBasicMaterial } from 'three';


class ARScene extends React.Component {
    constructor(props) {
        super(props);
        this.props = {
            currentContent: props.currentContent(),
            contentType: props.contentType(),
            playVideoWithPoster: props.playVideoWithPoster(),
            textureIndex: props.textureIndex(),
        };

        AFRAME.registerComponent('scan-qr', {
            init() {
                this.currentTargetName = getTargetName('target', window.location.href)
                this.previousContentType = props.contentType()
                this.previousContent = props.currentContent()

                this.videoEntity = null
                this.shouldDelay = true
            },
            tick() {
                const scannerElement = document.getElementById('qrCodeScanner')
                if (scannerElement !== null) {
                    if (this.shouldDelay) {
                        setTimeout(() => {
                            this.shouldDelay = false
                        }, 750);
                    }
                    else {
                        if (props.contentType !== null) props.onContentTypeChange(null, null)
                        const scannedURL = getQrURL()
                        if (scannedURL !== null) {
                            let targetName = getTargetName('target', scannedURL)
                            if (targetName !== this.currentTargetName) {
                                const contentType = loadTarget(targetName)
                                let currentContent = content[targetName]

                                this.currentTargetName = targetName

                                props.onContentTypeChange(contentType, currentContent)
                                props.onChange()
                            }
                        }
                        else if (getCurrentDetailName() === 'ANZ_DHL_QR') {
                            let targetName = 'ANZ_DHL_01'
                            if (targetName !== this.currentTargetName) {
                                const contentType = loadTarget(targetName)
                                let currentContent = content[targetName]

                                this.currentTargetName = targetName

                                props.onContentTypeChange(contentType, currentContent)
                                props.onChange()
                            }
                        }
                    }
                }
                else {
                    if (props.contentType() === null) {
                        props.onContentTypeChange(this.previousContentType, this.previousContent)
                    }
                    else {
                        this.previousContentType = props.contentType()
                        this.previousContent = props.currentContent()
                    }
                    this.shouldDelay = true
                }
                if (document.getElementById('videoEntity') !== null) {
                    if (this.videoEntity === null) this.videoEntity = document.getElementById('videoEntity')
                }
                else if (this.videoEntity !== null) {
                    this.videoEntity.removeAttribute('material')
                    this.videoEntity = null
                }
            }
        })

        if (!AFRAME.components['basic-mat']) {
            AFRAME.registerComponent('basic-mat', {
                schema: {
                    setBasic: { type: 'boolean' },
                },
                init: function () {
                    if (this.data.setBasic) {
                        this.el.addEventListener('model-loaded', () => {
                            // Grab the mesh / scene.
                            const obj = this.el.getObject3D('mesh');
                            // Go over the submeshes and modify materials we want.
                            obj.traverse(child => {
                                if (child.isMesh) {
                                    // const mat = new THREE.MeshBasicMaterial();
                                    // if(child.material.map) {
                                    //     mat.map = child.material.map;
                                    //     mat.map.encoding = THREE.sRGBEncoding;
                                    //     mat.needsUpdate = true;
                                    // }
                                    // if(child.material.transparent) {
                                    //     mat.transparent = child.material.transparent;
                                    // }
                                    // child.material = mat; 

                                    var prevMaterial = child.material;
                                    child.material = new THREE.MeshBasicMaterial();
                                    MeshBasicMaterial.prototype.copy.call(child.material, prevMaterial);

                                    if (child.material.map) {

                                        child.material.map.encoding = THREE.sRGBEncoding;
                                        child.material.needsUpdate = true;
                                    }

                                    // child.material.color.convertLinearToSRGB();

                                }
                            });
                        });
                    }
                }
            });
        }

        if (!AFRAME.components['rotate-local']) {
            AFRAME.registerComponent('rotate-local', {
                schema: {
                    direction: { type: 'vec3' },
                    speed: { type: 'number' }
                },
                init: function () {
                    if (this.data.speed <= 0) this.el.removeAttribute('rotate-local')
                    const self = this;
                    this.el.addEventListener('model-loaded', () => {
                        // Grab the mesh / scene.
                        const obj = this.el.getObject3D('mesh');
                        // Go over the submeshes and modify materials we want.
                        obj.traverse(child => {
                            if (child.isMesh) {
                                self.mesh = child;
                            }
                        });
                    });
                },
                tick: function (time, timeDelta) {
                    if (this.mesh) {
                        this.mesh.rotation.x += this.data.direction.x * timeDelta * this.data.speed * 0.0001;
                        this.mesh.rotation.y += this.data.direction.y * timeDelta * this.data.speed * 0.0001;
                        this.mesh.rotation.z += this.data.direction.z * timeDelta * this.data.speed * 0.0001;
                    }
                }
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        const contentType = nextProps.contentType;
        const currentContent = nextProps.currentContent;
        const playVideoWithPoster = nextProps.playVideoWithPoster;
        const textureIndex = nextProps.textureIndex;

        if (contentType && contentType !== this.props.contentType()) {
            this.setState({
                contentType
            })
        }
        if (currentContent && currentContent !== this.props.currentContent()) {
            this.setState({
                currentContent
            });
        }
        if (playVideoWithPoster && playVideoWithPoster !== this.props.playVideoWithPoster()) {
            this.setState({
                playVideoWithPoster
            });
        }
        if (textureIndex && textureIndex !== this.props.textureIndex()) {
            this.setState({
                textureIndex
            });
        }
    }

    render() {
        return (
            <a-scene id={'a-scene'}
                renderer={'colorManagement: true;'}
                xrweb={"disableWorldTracking: true"}
                xrextras-gesture-detector
                xrextras-almost-there
                xrextras-loading
                xrextras-runtime-error
                scan-qr
                light="defaultLightsEnabled: false">
                <a-assets>
                    <a-cubemap id="pisa">
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/px.png"></img>
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/nx.png"></img>
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/py.png"></img>
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/ny.png"></img>
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/pz.png"></img>
                        <img crossOrigin='anonymous' src="https://threejs.org/examples/textures/cube/pisa/nz.png"></img>
                    </a-cubemap>
                    <video id="video-mp4" crossOrigin="anonymous"></video>
                    <audio id="audio-file" crossOrigin="anonymous"></audio>
                    <img id="next-btn" src="./assets/img/icons8-forward_button.png"></img>
                    <img id="prev-btn" src="./assets/img/icons8-back_arrow.png"></img>
                    <img id="audio-btn" src="./assets/img/LautsprecherIcon.png"></img>
                    <img id="play-btn" src="./assets/img/interaktiv-play.png"></img>
                    <img id="load-btn" src="./assets/img/interaktiv-load.png"></img>
                </a-assets>

                <a-camera id="camera"
                    position={"0 4 10"}
                    raycaster="objects: .cantap"
                    cursor="fuse: false; rayOrigin: mouse;">
                </a-camera>
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 2"></a-entity> */}
                <a-light type="probe" envMap="#pisa" intensity="2"></a-light>
                <a-light type="directional" position="0 0 0" rotation="-90 0 0" target="#directionaltarget">
                    <a-entity id="directionaltarget" position="1 1 -1"></a-entity>
                </a-light>
                {/* <a-light type="ambient" intensity="0"></a-light> */}

                {this.props.contentType() !== null &&
                    <a-entity id={'mainObject3D'} image-target={'name: ' + this.props.currentContent().targetName}>

                        {this.props.contentType() === 'videoWithSound' &&
                            <VideoWithSound currentContent={this.props.currentContent()} playVideoWithPoster={this.props.playVideoWithPoster()} />
                        }
                        {this.props.contentType() === 'imageCarousel' &&
                            <ImageCarousel currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'imageSwipe' &&
                            <ImageSwipe currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'cardSwipe' &&
                            <CardSwipe currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'modelGLTF' &&
                            <ModelGLTF currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'datev' &&
                            <DATEV currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'globe' &&
                            <Globe currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'soccer' &&
                            <Soccer currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'portal' &&
                            <Portal currentContent={this.props.currentContent()} />
                        }
                        {this.props.contentType() === 'animals' &&
                            <Animals currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'frontPage' &&
                            <FrontPage currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'synthesizer' &&
                            <Synthesizer currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'solarsail' &&
                            <SolarSail currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'buildingblocks' &&
                            <BuildingBlocks currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'zalando' &&
                            <Zalando currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'map' &&
                            <Map currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === 'ruleOneTen' &&
                            <RuleOneTen currentContent={this.props.currentContent()} />
                        }

                        {this.props.contentType() === '360Portal' &&
                            <ThreeSixtyPortal currentContent={this.props.currentContent()} />
                        }
                    </a-entity>}


            </a-scene>

        );
    }
}

export default ARScene;
