/* globals AFRAME, digitalData, _satellite */
import { stat } from 'fs';
import React from 'react';
import * as THREE from 'three';

class RuleOneTen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")
        
        if (!AFRAME.components['rule-one-ten']) {
            AFRAME.registerComponent('rule-one-ten', {
                schema: {
                    width: { type: "number", default: 1 },
                    amount: { type: "number", default: 10 },
                },
                init: function () {
                    this.amount = this.data.amount;
                    this.width = this.data.width / this.amount;

                    this.cameraEl = document.getElementById('camera');
                    this.threeCamera = this.cameraEl.getObject3D("camera");

                    this.touchPlane = document.getElementById('touch-plane');

                    this.raycaster = new THREE.Raycaster();

                    this.count = this.amount * this.amount;

                    this.statusColorCodes = [
                        0x201402,
                        0x226611,
                        0xec5300, 
                        0xb20000,
                        0x060204
                    ];
                    this.color = new THREE.Color(this.statusColorCodes[0]);

                    this.neighbourhood = [
                        { x: 1, z: 0 },
                        { x: -1, z: 0 },
                        { x: 0, z: 1 },
                        { x: 0, z: 1 },
                        { x: 1, z: -1 },
                        { x: -1, z: -1 },
                        { x: 1, z: 1 },
                        { x: -1, z: 1 },
                    ]

                    const geometry = new THREE.PlaneGeometry(this.width, this.width);
                    geometry.rotateX(-Math.PI / 2);
                    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });

                    this.mesh = new THREE.InstancedMesh(geometry, material, this.count);
                    this.gridStatus = [];
                    let i = 0;
                    const offset = (this.amount - 1) * this.width / 2;

                    const matrix = new THREE.Matrix4();


                    for (let x = 0; x < this.amount; x++) {
                        this.gridStatus.push([]);
                        for (let z = 0; z < this.amount; z++) {

                            matrix.setPosition(offset - x * this.width, 0.01, offset - z * this.width);

                            this.mesh.setMatrixAt(i, matrix);
                            this.mesh.setColorAt(i, this.color);
                            this.gridStatus[x].push(0);
                            i++;
                        }

                    }
                    this.el.object3D.add(this.mesh);
                    this.mouseDownHandler = this.mouseDownHandler.bind(this);
                    this.touchPlane.addEventListener('mousedown', this.mouseDownHandler);
                    this.stop = false;
                },

                tick: function () {
                    if (this.mesh && this.mesh.count == this.count && !this.stop) {
                        this.gridStatus = this.iterateGrid();
                        this.updateGridColor();
                    }
                },

                iterateGrid: function () {

                    let tempGrid = []
                    for (let x = 0; x < this.amount; x++) {
                        tempGrid.push([]);
                        for (let z = 0; z < this.amount; z++) {
                            tempGrid[x].push(0);
                        }
                    }
                    for (let x = 1; x < this.amount - 1; x++) {
                        for (let z = 1; z < this.amount - 1; z++) {
                            const currentGridStatus = this.gridStatus[x][z];
                            if (currentGridStatus == 0 && Math.random() < 0.005) {
                                tempGrid[x][z] = 1;
                            }
                            else if (currentGridStatus == 1) {
                                tempGrid[x][z] = 1;
                                for (let n = 0; n < this.neighbourhood.length; n++) {
                                    const neighbour = this.neighbourhood[n];
                                    if (Math.abs(neighbour.x) == Math.abs(neighbour.z) && Math.random() < 0.573) continue;
                                    if (this.gridStatus[x + neighbour.x][z + neighbour.z] == 2) {
                                        tempGrid[x][z] = 2;
                                        break;
                                    }
                                }
                            }
                            else if (currentGridStatus == 2) {
                                if(Math.random() <= 0.001) { 
                                    tempGrid[x][z] = 2;
                                }
                                else {
                                    tempGrid[x][z] = 3;
                                }
                            }
                            else if (currentGridStatus == 3) {
                                if(Math.random() <= 0.7) { 
                                    tempGrid[x][z] = 3;
                                }
                                else {
                                    tempGrid[x][z] = 4;
                                }
                            }
                            else if (currentGridStatus == 4 && Math.random() <= 0.5) tempGrid[x][z] = 4;  
                        }
                    }
                    return tempGrid;
                },

                updateGridColor: function () {

                    for (let x = 0; x < this.amount; x++) {
                        for (let z = 0; z < this.amount; z++) {
                            const currentTileStatus = this.gridStatus[x][z];
                            this.mesh.setColorAt(x * this.gridStatus.length + z, this.color.setHex(this.statusColorCodes[currentTileStatus]));
                            this.mesh.instanceColor.needsUpdate = true;
                        }
                    }

                },

                remove: function () {
                },

                mouseDownHandler: function (e) {
                    if (e.detail.intersection) {
                        let point = e.detail.intersection.point.clone();
                        point = this.el.object3D.worldToLocal(point);

                        const percentagePerTile = 100 / this.amount;
                        const tileRange = this.amount * this.width / 2;
                        const xPercentage = Math.floor((THREE.MathUtils.inverseLerp(tileRange, -tileRange, point.x) * 100));
                        const zPercentage = Math.floor((THREE.MathUtils.inverseLerp(tileRange, -tileRange, point.z) * 100));

                        const xIDX = Math.floor(xPercentage / percentagePerTile);
                        const zIDX = Math.floor(zPercentage / percentagePerTile);

                        if (this.gridStatus[xIDX][zIDX] == 1) this.gridStatus[xIDX][zIDX] = 2;
                        else {
                            for (let index = zIDX; index < this.amount - 1; index++) {
                                const tempTileStatus = this.gridStatus[xIDX][index];
                                if(tempTileStatus == 1) {
                                    this.gridStatus[xIDX][index] = 2;
                                    break;
                                }
                            }
                        }

                        _satellite.track('content-clicked');
                    }
                },

            })
        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'} class={'cantap'}>
                    <a-entity
                        rule-one-ten={'width: ' + this.state.currentContent.width + '; amount:' + this.state.currentContent.amount + ';'}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'model-rule-one-ten'}>
                    </a-entity>
                    <a-entity
                        geometry={'primitive: plane; height: ' + this.state.currentContent.width + '; width: ' + this.state.currentContent.width + ';'}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={(this.state.currentContent.rotation.x + 90) + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        material="side: double; color: red;"
                        id={'touch-plane'} class={'cantap'}
                        visible={false}
                    >
                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
            </a-entity>
        )
    }
}
export default RuleOneTen;
