/* globals XR8, content */

//import content from "./content";

const getTargetName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const loadTarget = (targetName) => {
    if(targetName !== null)
    {
        XR8.XrController.configure({imageTargets: [targetName]});
        console.log('targetName loaded!', targetName);
    
        let contentType = content[targetName].type;
    
        return contentType;
    }
    return null;
};

export {getTargetName, loadTarget}