/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three'
import RAPIER from "@dimforge/rapier3d-compat";

class Soccer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D'

        digitalData.media = media
        _satellite.track("Media Scanned")


        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        }

        if (!AFRAME.components['physics-scene']) {
            AFRAME.registerComponent('physics-scene', {
                schema: {
                    radius: { type: 'number' },
                    force: { type: 'vec2' }
                },

                init: function () {
                    this.kicked = 0;
                    this.globalGravity = new RAPIER.Vector3(0, -9.81, 0);
                    this.dimensions = new THREE.Vector3(2, 1, 2);

                    this.soccerBallEl = document.getElementById('active-object').object3D;

                    this.startSimulation = this.startSimulation.bind(this);
                    RAPIER.init().then(this.startSimulation)
                },
                clickHandler: function (event) {
                    this.soccerBallEl.rigidBody.applyImpulse(
                        {
                            x: getRandomInt(this.data.force.x, this.data.force.y),
                            y: getRandomInt(this.data.force.x, this.data.force.y),
                            z: getRandomInt(this.data.force.x, this.data.force.y)
                        }, true)
                    ++this.kicked;
                },
                remove: function () {
                    this.soccerBallEl.el.removeEventListener('click', this.clickHandler); 
                },

                tick: function (time, timeDelta) {
                    if (this.physicsScene) {
                        this.physicsScene.step();
                        this.updateBall();
                    }
                },
                startSimulation: function () {
                    this.physicsScene = new RAPIER.World(this.globalGravity);
                    this.ballDataEl = document.getElementById('ball-data');
                    this.setupPlatform();
                    this.setupBall();
                },
                setupBall: function () {
                    const rigidBodyDesc = RAPIER.RigidBodyDesc.dynamic()
                        .setTranslation(
                            this.soccerBallEl.position.x,
                            this.soccerBallEl.position.y,
                            this.soccerBallEl.position.z
                        )
                    // .setGravityScale(1)
                    // .setCcdEnabled(true)
                    // .setLinearDamping(0.1)
                    // .setAngularDamping(0.5);

                    this.soccerBallEl.rigidBody = this.physicsScene.createRigidBody(rigidBodyDesc);

                    const colliderDesc = RAPIER.ColliderDesc.ball(this.data.radius).setDensity(50);
                    const collider = this.physicsScene.createCollider(colliderDesc, this.soccerBallEl.rigidBody);

                    this.clickHandler = this.clickHandler.bind(this);
                    this.soccerBallEl.el.addEventListener('click', this.clickHandler)
                },
                setupPlatform: function () {

                    const ball = document.getElementById('active-object').object3D;
                    // this.dimensions.multiplyScalar(ball.scale.x);

                    this.pivotPoint = ball.position.clone();

                    const rigidBodyDesc = RAPIER.RigidBodyDesc.kinematicPositionBased()
                        .setTranslation(
                            this.pivotPoint.x,
                            this.pivotPoint.y - (this.dimensions.z / 2 + this.dimensions.y / 2),
                            this.pivotPoint.z
                        ).setCcdEnabled(true);
                    const rigidBody = this.physicsScene.createRigidBody(rigidBodyDesc);

                    // Ground
                    const colliderDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.x,
                        this.dimensions.y,
                        this.dimensions.z
                    );
                    this.physicsScene.createCollider(colliderDesc, rigidBody);

                    // Wall Top
                    var wallDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.x,
                        this.dimensions.y,
                        this.dimensions.z
                    ).setTranslation(
                        0,
                        (this.dimensions.z + this.dimensions.y),
                        0
                    );
                    this.physicsScene.createCollider(wallDesc, rigidBody);

                    // Wall Right
                    wallDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.y,
                        this.dimensions.x,
                        this.dimensions.z
                    ).setTranslation(
                        (this.dimensions.z / 2 + this.dimensions.y / 2),
                        (this.dimensions.z / 2 + this.dimensions.y / 2),
                        0
                    );
                    this.physicsScene.createCollider(wallDesc, rigidBody);

                    // Wall Left
                    wallDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.y,
                        this.dimensions.x,
                        this.dimensions.z
                    ).setTranslation(
                        -(this.dimensions.z / 2 + this.dimensions.y / 2),
                        (this.dimensions.z / 2 + this.dimensions.y / 2),
                        0
                    );
                    this.physicsScene.createCollider(wallDesc, rigidBody);

                    // Wall Front
                    wallDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.x,
                        this.dimensions.z,
                        this.dimensions.y
                    ).setTranslation(
                        0,
                        (this.dimensions.z / 2 + this.dimensions.y / 2),
                        (this.dimensions.z / 2 + this.dimensions.y / 2)
                    );
                    this.physicsScene.createCollider(wallDesc, rigidBody);

                    // Wall Back
                    wallDesc = RAPIER.ColliderDesc.cuboid(
                        this.dimensions.x,
                        this.dimensions.z,
                        this.dimensions.y
                    ).setTranslation(
                        0,
                        (this.dimensions.z / 2 + this.dimensions.y / 2),
                        -(this.dimensions.z / 2 + this.dimensions.y / 2)
                    );
                    this.physicsScene.createCollider(wallDesc, rigidBody);

                },
                updateBall: function () {
                    if (this.soccerBallEl) {
                        var position = this.soccerBallEl.rigidBody.translation();
                        const rotation = this.soccerBallEl.rigidBody.rotation();
                        const linvel = this.soccerBallEl.rigidBody.linvel();

                        this.soccerBallEl.position.x = position.x;
                        this.soccerBallEl.position.y = position.y;
                        this.soccerBallEl.position.z = position.z;

                        this.soccerBallEl.setRotationFromQuaternion(
                            new THREE.Quaternion(
                                rotation.x,
                                rotation.y,
                                rotation.z,
                                rotation.w
                            )
                        );

                        this.ballDataEl.innerText = 
                        `const Ball gFWCQ2209{ 
                            Position: {x: ` + position.x.toFixed(2) + `,y: ` + position.y.toFixed(2) + `,z: `+ position.z.toFixed(2) + `},
                            Quaternion: {w: ` + rotation.w.toFixed(2) + `,x: ` + rotation.x.toFixed(2) + `,y: ` + rotation.y.toFixed(2) + `,z: ` + rotation.z.toFixed(2) + `},
                            Velocity: {x: ` + linvel.x.toFixed(2) + `,y: ` + linvel.y.toFixed(2) + `,z: `+ linvel.z.toFixed(2) + `},
                            Kicked: ` + this.kicked + `x times};`
                    }
                },
            });
        }


    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'} physics-scene={'radius: 0.2; force: ' + this.state.currentContent.force.min + ' ' + this.state.currentContent.force.max + ';'}>

                    <a-entity geometry="primitive: sphere; radius: 0.2"
                        class={'cantap'}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'active-object'}>
                            <a-entity gltf-model={this.state.currentContent.URL}
                            basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            rotation={'0 90 90'}
                            scale={this.state.currentContent.ballScale.x + "  " + this.state.currentContent.ballScale.y + " " + this.state.currentContent.ballScale.z}
                            ></a-entity>
                    </a-entity>

                </a-entity>
            </a-entity>
        )
    }
}
export default Soccer;
