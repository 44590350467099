/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
class CardSwipe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }

    componentDidMount() {

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Image Slider'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['one-finger-move-2']) {
            AFRAME.registerComponent('one-finger-move-2', {
                schema: {
                    factor: { default: 3 },
                    imageURL: { type: 'string' },
                    imageAmount: { type: "number" },
                    imageFormat: { type: "string" },
                    initialX: {type: "number"},
                    imageWidth: {type:"number"}
                },
                init() {
                    this.length = this.data.imageAmount;
                    const loader = new THREE.TextureLoader();
                    this.textures = [];

                    this.highestSelector = 0;

                    
                    loader.load(this.data.imageURL + (this.textures.length + 1) + this.data.imageFormat, onload);
                    const self = this;
                    function onload(texture) {
                        texture.encoding = THREE.sRGBEncoding;
                        self.textures.push(texture);
                        if (self.textures.length < self.length) {
                            loader.load(self.data.imageURL + (self.textures.length + 1) + self.data.imageFormat, onload)
                        }
                    }

                    this.handleEvent = this.handleEvent.bind(this);
                    this.el.sceneEl.addEventListener('onefingermove', this.handleEvent);
                },
                remove() {
                    this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
                },
                handleEvent(event) {
                    let touchPositionX = this.el.object3D.position.x + event.detail.positionChange.x * this.data.factor;
                    // console.log('touchPositionX', touchPositionX);

                    if (touchPositionX > 0 && touchPositionX < this.data.imageWidth) {
                        this.el.object3D.position.x += event.detail.positionChange.x * this.data.factor;

                        const invLerp = THREE.MathUtils.inverseLerp(0, this.data.imageWidth, touchPositionX);
                        var currentSelector = Math.floor(invLerp * (this.length));
                        const cardEl = document.getElementById('card-el');
                        const s = this;
                        cardEl.object3D.traverse((child) => {
                            if (child.isMesh) {
                                if (s.textures[currentSelector] !== null && child.material.map !== s.textures[currentSelector])
                                    child.material.map = s.textures[currentSelector];
                            }
                        })

                        const progressBarEl = document.getElementById('progress-bar');
                        progressBarEl.object3D.position.x = this.data.initialX - this.data.imageWidth * (0.5 * (1 - invLerp));
                        progressBarEl.object3D.scale.x = invLerp;
                        const inverseProgressBarEl = document.getElementById('inverse-progress-bar');
                        inverseProgressBarEl.object3D.position.x = this.data.initialX + this.data.imageWidth * (0.5 * invLerp);
                        inverseProgressBarEl.object3D.scale.x = 1 - invLerp;

                        if(currentSelector > this.highestSelector){
                            this.highestSelector = currentSelector;
                            _satellite.track("slider-moved-right");
                        }
                    }
                }
            });
        }
    }

    render() {
        return (
            // <a-entity xrextras-named-image-target={'name: ' + this.state.currentContent.targetName + ';'}>
            <a-entity>
                <a-entity one-finger-move-2={'imageWidth: ' + this.state.currentContent.size.w + '; initialX: ' + this.state.currentContent.position.x + '; factor:3; imageURL:' + this.state.currentContent.image + '; imageAmount:' + this.state.currentContent.imageAmount + '; imageFormat: ' + this.state.currentContent.imageFormat + ';'} position={(this.state.currentContent.position.x) + ' ' + (this.state.currentContent.position.y - this.state.currentContent.size.h / 2 - 0.03) + ' 0.005'}>
                    <a-image className={'cantap'} src={"./assets/img/interaktiv-arrow.png"} width={'0.15'} height={"0.15"} position={'-' + this.state.currentContent.size.w / 2 + ' 0 0.003'}></a-image>
                </a-entity>

                <a-entity id={'card-el'}
                    geometry={"primitive: plane; height: " + this.state.currentContent.size.h + "; width: " + this.state.currentContent.size.w + ";"}
                    position={(this.state.currentContent.position.x) + ' ' + this.state.currentContent.position.y + ' 0'}
                    material={"src: " + this.state.currentContent.image + "1" + this.state.currentContent.imageFormat + "; shader: flat;"}
                    scale="1 1 1">
                </a-entity>

                <a-entity id={'progress-bar'}
                    geometry={"primitive: plane; height: " + 0.02 + "; width: " + this.state.currentContent.size.w + ";"}
                    position={(this.state.currentContent.position.x) + ' ' + (this.state.currentContent.position.y - this.state.currentContent.size.h / 2 - 0.03) + ' 0.003'}
                    material={"color: #110A35; shader: flat;"}
                    scale="0 1.3 1">
                </a-entity>
                <a-entity id={'inverse-progress-bar'}
                    geometry={"primitive: plane; height: " + 0.02 + "; width: " + this.state.currentContent.size.w + ";"}
                    position={(this.state.currentContent.position.x) + ' ' + (this.state.currentContent.position.y - this.state.currentContent.size.h / 2 - 0.03) + ' 0.002'}
                    material={"color: #110A35; shader: flat; transparent: true; opacity: 0.7;"}
                    scale="1 1.3 1">
                </a-entity>


                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.size.w + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' -' + (this.state.currentContent.size.h / 2 + 0.05 + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.size.w - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>}
            </a-entity>
        )
    }
}

export default CardSwipe;