import React from 'react';
import './external-link.css';

class EXTERNAL_LINK extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent,
        }
    }

    do = () => {
        console.log()
    }

    render () {
        return (
            <div className={'external-link'}>
               <div className={'external-link-btn-container'}>
                   <a className={'external-link-btn'} href={this.state.currentContent.externalURL} target={'_blank'}>
                       <img src={this.state.currentContent.externalLinkText}></img>
                    </a>
               </div>
            </div>
        )}
}
export default EXTERNAL_LINK;