import React from 'react';
import './LookingForTarget.css';

class LookingForTarget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
    }

    render () {


        return (
            <div className={'LookingForTarget'}>
                <img src={'./assets/img/ar-icon.png'} alt={'ar-scan'}/>
                <p id={'LookingForTargetStyle'}>
                    Halten Sie Ihr Smartphone <br />auf das Bild mit diesem Zeichen.
                </p>
            </div>
        )}
}
export default LookingForTarget;