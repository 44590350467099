/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Karte interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")

        // console.log(this.state.currentContent)

        function playAnimation(object, time, from, to, offset) {
            var t = {
                'rot': 0,
                'moveZ': 0,
            }
            var isAlreadyPlaying = false
            for (let i = 0; i < AFRAME.ANIME.running.length; i++) {
                if (AFRAME.ANIME.running[i].animatables[0].target.name) {
                    isAlreadyPlaying = true
                    break
                }
            }
            if (!isAlreadyPlaying) {
                var objectRot = object.object3D.rotation.clone()
                var isFlipped = false
                AFRAME.ANIME({
                    targets: t,
                    rot: Math.PI,
                    duration: time,
                    delay: 50,
                    easing: 'easeInOutSine',
                    update: function () {
                        object.object3D.rotation.y = objectRot.y + t.rot
                        if (t.rot >= Math.PI / 2 && !isFlipped) {
                            isFlipped = true
                            if (object.getAttribute('src') === from) {
                                object.setAttribute('src', to)
                                // object.setAttribute('material', 'shader: flat; transparent: true; side: double; repeat: -1 1'); 
                                object.setAttribute('width', 1.78)
                                object.setAttribute('height', 1)
                            }
                            else {
                                object.setAttribute('src', from);
                                object.setAttribute('width', 0.15)
                                object.setAttribute('height', 0.15)
                            }
                        }
                    }
                })
                var objectPos = object.object3D.position.clone()
                AFRAME.ANIME({
                    targets: t,
                    moveZ: 0.1,
                    duration: time,
                    loop: 2,
                    direction: 'alternate',
                    easing: 'easeInOutSine',
                    update: function () {
                        if (object.getAttribute('src') === from) {
                            object.object3D.position.z = objectPos.z + t.moveZ - offset
                        }
                        else {
                            object.object3D.position.z = objectPos.z + t.moveZ + offset
                        }
                    }
                })
            }
        }

        if (!AFRAME.components['flip-card']) {
            AFRAME.registerComponent('flip-card', {
                schema: {
                    time: {
                        type: "number",
                        default: 500
                    },
                    flipFrom: {
                        type: "string",
                        default: ''
                    },
                    flipTo: {
                        type: "string",
                        default: '',
                    },
                    offset: {
                        type: "number",
                        default: 0.002
                    }
                },
                init() {
                    this.el.classList.add("cantap")
                    const flipAnimation = () => {
                        playAnimation(this.el, this.data.time, this.data.flipFrom, this.data.flipTo, this.data.offset)
                        _satellite.track('content-clicked');
                    }

                    this.el.addEventListener('click', flipAnimation)
                }
            });
        }

        if (!AFRAME.components['map-clicked']) {
            AFRAME.registerComponent('map-clicked', {
                schema: {
                    maxDistance: {default: 0.06}
                },
                init() {
                    this.el.classList.add("cantap")
                    this.flipCards = [];
                    for (let idx = 0; idx < this.el.object3D.parent.children.length; idx++) {
                        const element = this.el.object3D.parent.children[idx];
                        if (element != this.el.object3D) this.flipCards.push(element);
                    }

                    this.mouseDownHandler = this.mouseDownHandler.bind(this);
                    this.el.addEventListener('mousedown', this.mouseDownHandler);
                },

                mouseDownHandler: function (e) {
                    if (e.detail.intersection) {
                        let point = e.detail.intersection.point.clone();
                        point = this.el.object3D.worldToLocal(point);

                        let closestFlipCard = {
                            distance: 999,
                            flipCard: null
                        }
                        for (let idx = 0; idx < this.flipCards.length; idx++) {
                            const flipCard = this.flipCards[idx];
                            const distance = flipCard.position.distanceTo(point);
                            if(distance <  closestFlipCard.distance) {
                                closestFlipCard.distance = distance;
                                closestFlipCard.flipCard = flipCard;
                            }
                        }
                        if(closestFlipCard.flipCard && closestFlipCard.distance <= this.data.maxDistance) {
                            closestFlipCard.flipCard.el.click();
                        }
                    }
                }
            })
        }
    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity>

                    <a-entity
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " 0"}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.string}>
                        <a-entity
                            id={'cover'}
                            geometry={"primitive: plane; height: " + this.state.currentContent.coverDimensions.y + "; width: " + this.state.currentContent.coverDimensions.x + " ;"}
                            position={'0 0 0'}
                            material={"shader: flat; src: " + this.state.currentContent.cover + "; transparent: true"}
                            scale="1 1 1" map-clicked={'maxDistance: 0.06'}>
                        </a-entity>

                        <a-image
                            id={'f1'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[0].back + "; offset: 0.001"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.18 0.27 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f2'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[1].back + "; offset: 0.002"}
                            width={'0.15'} height={'0.15'}
                            position={'0.11 0.26 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f3'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[2].back + "; offset: 0.003"}
                            width={'0.15'} height={'0.15'}
                            position={'0.09 0.14 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f4'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[3].back + "; offset: 0.004"}
                            width={'0.15'} height={'0.15'}
                            position={'0.19 0.18 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f5'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[4].back + "; offset: 0.005"}
                            width={'0.15'} height={'0.15'}
                            position={'0.35 0.29 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f6'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[5].back + "; offset: 0.006"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.08 0.25 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f7'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[6].back + "; offset: 0.007"}
                            width={'0.15'} height={'0.15'}
                            position={'0.01 -0.23 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f8'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[7].back + "; offset: 0.008"}
                            width={'0.15'} height={'0.15'}
                            position={'0.19 0.25 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f9'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[8].back + "; offset: 0.009"}
                            width={'0.15'} height={'0.15'}
                            position={'0.05 0.11 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f10'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[9].back + "; offset: 0.01"}
                            width={'0.15'} height={'0.15'}
                            position={'0.04 0.16 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f11'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[10].back + "; offset: 0.011"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.47 0.07 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f12'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[11].back + "; offset: 0.012"}
                            width={'0.15'} height={'0.15'}
                            position={'0.34 -0.39 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f13'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[12].back + "; offset: 0.013"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.09 0.18 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f14'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[13].back + "; offset: 0.014"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.66 0.18 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f15'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[14].back + "; offset: 0.015"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.24 0.3 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                        <a-image
                            id={'f16'}
                            flip-card={"time: 400; flipFrom: " + this.state.currentContent.flipcards[0].front + "; flipTo: " + this.state.currentContent.flipcards[15].back + "; offset: 0.016"}
                            width={'0.15'} height={'0.15'}
                            position={'-0.18 0.03 0.003'}
                            src={this.state.currentContent.flipcards[0].front}
                            material={"shader: flat; transparent: true; side: double; repeat: -1 1; depthTest: true;"}
                            scale="0.3 0.3 0.3">
                        </a-image>

                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
            </a-entity>
        )
    }
}
export default Map;
