import React from 'react';
import logo from '../assets/img/logo-small.png';
import './Logo.css';

class Logo extends React.Component {

    render () {


        return (
            <div className={'logo'}>
                <img src={logo} alt={'logo'}/>
            </div>
        )}
}
export default Logo;