
import React from 'react';
import icon from '../assets/img/qr-code-scanner-icon-NEW.png';
import './QrCodeBtn.css';

class QrCodeBtn extends React.Component {


    render() {
        return (
            <div className={'qrCodeBtn'} onClick={this.props.onClick}>
                <img src={icon} alt={'qr-code-icon'}/>
            </div>
        )
    }
}

export default QrCodeBtn;