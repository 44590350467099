/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
class ImageSwipe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }

    componentDidMount() {

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Image Slider'

        digitalData.media = media
        _satellite.track("Media Scanned")

        const self = this;

        if (!AFRAME.components['one-finger-move']) {
        AFRAME.registerComponent('one-finger-move', {
            schema: {
                factor: {default: 3},
            },
            init() {
                this.handleEvent = this.handleEvent.bind(this);
                this.el.sceneEl.addEventListener('onefingermove', this.handleEvent);
            },
            remove() {
                this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
            },
            handleEvent(event) {
                let touchPositionX = this.el.object3D.position.x + event.detail.positionChange.x * this.data.factor;
                // console.log('touchPositionX', touchPositionX);

                if(touchPositionX > 0 && touchPositionX < self.state.currentContent.size.w){
                    this.el.object3D.position.x += event.detail.positionChange.x * this.data.factor;

                    const invLerp = THREE.MathUtils.inverseLerp(0,self.state.currentContent.size.w, touchPositionX);
                    const leftSliderEl = document.getElementById('left-slider-el');
                    leftSliderEl.object3D.position.x = self.state.currentContent.position.x - self.state.currentContent.size.w * (0.5 * (1 - invLerp));
                    leftSliderEl.object3D.scale.x = invLerp;
                    leftSliderEl.object3D.traverse((child)=>{
                        if(child.isMesh) {
                            child.material.map.repeat.x = invLerp;
                        }
                    })

                    const rightSliderEl = document.getElementById('right-slider-el');
                    rightSliderEl.object3D.position.x = self.state.currentContent.position.x + self.state.currentContent.size.w * (0.5 * invLerp);
                    rightSliderEl.object3D.scale.x = 1 - invLerp;
                    rightSliderEl.object3D.traverse((child)=>{
                        if(child.isMesh) {
                            child.material.map.wrapS = THREE.RepeatWrapping;
                            child.material.map.wrapT = THREE.RepeatWrapping;
                            child.material.map.repeat.x = 1 - invLerp;
                            child.material.map.offset.x = invLerp;
                        }
                    })
                }
            }
        });
    }
    }

    render() {
        return (
            // <a-entity xrextras-named-image-target={'name: ' + this.state.currentContent.targetName + ';'}>
            <a-entity>
                <a-entity one-finger-move={'factor:3;'} position={(this.state.currentContent.size.w/2)+this.state.currentContent.position.x+' '+this.state.currentContent.position.y+' 0.005'}>

                    <a-image className={'cantap'} src={"./assets/img/interaktiv-arrow.png"} width={'0.2'} height={"0.2"} position={'-'+this.state.currentContent.size.w/2+' 0 0.003'}></a-image>
                </a-entity>
                
                <a-entity id={'left-slider-el'} geometry={"primitive: plane; height: " + this.state.currentContent.size.h + "; width: " + this.state.currentContent.size.w + ";"}
                          position={(this.state.currentContent.position.x - this.state.currentContent.size.w * (0.5 * 0.5))+ ' '+this.state.currentContent.position.y+' 0'}
                          material={"src: " + this.state.currentContent.firstImage + "; repeat: 0.5 1;"}
                          scale="0.5 1 1">
                </a-entity>

                <a-entity id={'right-slider-el'} geometry={"primitive: plane; height: " + this.state.currentContent.size.h + "; width: " + this.state.currentContent.size.w + ";"}
                          position={(this.state.currentContent.position.x + this.state.currentContent.size.w * (0.5 * 0.5))+ ' '+this.state.currentContent.position.y+' 0'}
                          material={"src: " + this.state.currentContent.secondImage + "; offset: 0.5 0; repeat: 0.5 1;"}
                          scale="0.5 1 1">
                </a-entity>

                {this.state.currentContent.text &&
                <a-entity geometry={"primitive: plane; width: "+this.state.currentContent.size.w+"; height: auto"}
                          material={"color: #110a35"}
                          position={this.state.currentContent.position.x +' -'+ (this.state.currentContent.size.h/2+0.05) +' 0.0015'}
                          text={'value:'+this.state.currentContent.text+ '; color: white; width: '+(this.state.currentContent.size.w-0.08)+'; height: auto'}>
                </a-entity>}
            </a-entity>
        )
    }
}

export default ImageSwipe;