import React from 'react';
import './ANZ_DHL.css';

class ANZ_DHL extends React.Component {

    render () {


        return (
            <div className={'ANZ_DHL'}>

               <div className={'ANZ_DHL-btn-container'}>
                   <a className={'ANZ_DHL-btn'} href={'https://www.dhl.com/coldplay'} target={'_blank'}>Mehr erfahren</a>
               </div>
            </div>
        )}
}
export default ANZ_DHL;