
/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';

class ThreeSixtyPortal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '360 Video'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['portal-animation']) {
            AFRAME.registerComponent('portal-animation', {
                schema: {
                    innerRadius: { type: "number" },
                    duration: { type: "number" }
                },

                init: function () {
                    this.foundHandller = this.foundHandller.bind(this);
                    this.el.sceneEl.addEventListener('xrimagefound', this.foundHandller)
                },

                remove: function () {
                    this.el.sceneEl.removeEventListener('xrimagefound', this.foundHandller)
                },

                foundHandller: function () {
                    const self = this;
                    setTimeout(() => {
                        self.el.setAttribute('animation__scaleIn', {
                            property: 'scale',
                            dur: self.data.duration,
                            from: '0.01 0.01 0.01',
                            to: self.data.innerRadius + ' ' + self.data.innerRadius + ' ' + self.data.innerRadius,
                            easing: 'easeOutElastic',
                        })
                    }, 200)
                }
            });

        }

        if (!AFRAME.components['rotate-video-sphere']) {
            AFRAME.registerComponent('rotate-video-sphere', {
                schema: {
                    urlVideo: {
                        type: 'string'
                    },
                    factor: { type: "number", default: 2 }
                },
                init: function () {
                    const vid = document.getElementById('video-mp4')
                    vid.src = this.data.urlVideo
                    // vid.setAttribute('loop', 'false')
                    vid.setAttribute('crossOrigin', 'anonymous')
                    vid.load()
                    // vid.muted = true;
                    //vid.loop = true;

                    this.video = vid;

                    this.el.setAttribute('material', 'src', vid);

                    var isPlaying = false

                    this.playbutton = document.getElementById('playBtn')
                    const self = this;
                    const play = () => {
                        if (!isPlaying) {
                            if (vid.currentTime <= 0.1) _satellite.track("Media - Video Started")
                            if (vid.muted) vid.muted = false;
                            vid.play()
                            self.playbutton.object3D.visible = false
                            isPlaying = true
                        } else if (this.data.canstop) {
                            vid.pause()
                        }
                    }

                    // el.addEventListener('click', play)
                    this.playbutton.addEventListener('click', play)

                    this.handleEnded = this.handleEnded.bind(this)
                    this.isPlaying = isPlaying;
                    vid.addEventListener('ended', this.handleEnded);

                    vid.addEventListener('pause', () => {
                        isPlaying = false
                        self.playbutton.object3D.visible = true
                    })

                    this.handleLoadeddata = this.handleLoadeddata.bind(this);
                    this.video.addEventListener('loadeddata', this.handleLoadeddata);
                    this.handleCanPlayThrough = this.handleCanPlayThrough.bind(this);
                    this.video.addEventListener('canplaythrough', this.handleCanPlayThrough);
                    this.markerFoundHandler = this.markerFoundHandler.bind(this);
                    this.el.sceneEl.addEventListener('xrimagefound', this.markerFoundHandler);
                    this.markerUpdatedHandler = this.markerUpdatedHandler.bind(this);
                    this.el.sceneEl.addEventListener('xrimageupdated', this.markerUpdatedHandler);
                    this.markerLostHandler = this.markerLostHandler.bind(this);
                    this.el.sceneEl.addEventListener('xrimagelost', this.markerLostHandler);
                    this.fingerMoveHandler = this.fingerMoveHandler.bind(this);
                    this.el.sceneEl.addEventListener("onefingermove", this.fingerMoveHandler);
                },

                tick: function(timeDelta) {
                    if(!this.canPlay && this.playbutton) {
                        this.playbutton.object3D.rotation.y -= Math.PI / 64;
                    }
                    else if(this.playbutton.object3D.rotation.y != 0) {
                        this.playbutton.object3D.rotation.y = 0;
                    }
                },

                remove: function () {
                    if (this.video) this.video.pause();
                    this.video.loop = false;
                    this.video.muted = false;
                    this.video.removeEventListener('ended', this.handleEnded);
                    this.video.removeEventListener('loadeddata', this.handleLoadeddata);
                    this.video.removeEventListener('canplaythrough', this.handleCanPlayThrough);
                },

                handleEnded() {
                    console.log('video finished')
                    _satellite.track("Media - Video Completed")
                    if (!this.video.loop) {
                        this.isPlaying = false
                        this.video.currentTime = 0
                    }
                },

                handleLoadeddata: function () {
                    const video = this.video;
                    setTimeout(()=>{
                        this.video.pause();
                        this.video.currentTime = 0;
                        this.video.muted = false;
                    },100)
                },

                handleCanPlayThrough: function(e) {
                    this.canPlay = true;
                    this.playbutton.setAttribute('class', 'cantap')
                    this.playbutton.setAttribute('src', '#play-btn')
                    this.playbutton.object3D.rotation.y = 0;
                },

                markerFoundHandler: function () {
                    // if (this.video && this.video.paused) this.video.play();
                },

                markerUpdatedHandler: function (e) {
                    // if (this.video && this.video.paused) this.video.play();
                    if (e.detail.rotation.z < 0) { this.invertRotation = -1 };
                    if (e.detail.rotation.z > 0) { this.invertRotation = 1 };
                },

                markerLostHandler: function () {
                    if (this.video) this.video.pause();
                },

                fingerMoveHandler: function (e) {
                    this.el.object3D.rotateOnAxis(new THREE.Vector3(0, 1, 0), e.detail.positionChange.x * this.data.factor * -1);
                }
            });
        }

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity>

                    {/* <a-ring
                        id="portal"
                        portal-animation={'innerRadius: ' + this.state.currentContent.innerRadius + '; duration: ' + this.state.currentContent.duration + ';'}
                        radius-inner="0.001"
                        radius-outer="1000"
                        xrextras-hider-material
                    ></a-ring> */}
                    <a-entity
                        gltf-model={'./assets/model/portalFrame.glb'}
                        portal-animation={'innerRadius: ' + this.state.currentContent.scale + '; duration: ' + this.state.currentContent.duration + ';'}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        rotation={'0 90 90'}
                        xrextras-hider-material
                        scale='0.01 0.01 0.01' class={'cantap'}>
                        {/* <a-plane
                            width='1'
                            height='00.157'
                            position="-0.62 0.01 -0.7"
                            rotation='-90 0 -90'
                            scale='0.6 0.6 0.6'
                            material='shader:flat; side:double;'
                            src="./assets/img/Z_FE_03/Z_FE_03.jpg"
                            ></a-plane> */}
                        <a-image
                            id={'playBtn'}
                            src={"#load-btn"} width={'0.3'} height={"0.3"}
                            rotation={'90 0 90'}
                            position={'0 0 0'}>
                        </a-image>
                    </a-entity>

                    <a-sky
                        rotate-video-sphere={'urlVideo: ' + this.state.currentContent.URL}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        scale="0.015 0.015 -0.015"
                        material='shader: flat;'
                        radius='300' class={'cantap'}
                    ></a-sky>

                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
            </a-entity>
        )
    }
}
export default ThreeSixtyPortal;
