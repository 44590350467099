
import React from 'react';
import './Footer.css';

class Footer extends React.Component {


    render() {
        return (
            <div className={'footer'}>
                <a href={'https://m.faz.net/datenschutz/'} target={'_blank'}>Datenschutz</a>
                <a href={'https://m.faz.net/impressum/'} target={'_blank'}>Impressum</a>
            </div>
        )
    }
}

export default Footer;