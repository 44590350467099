/* globals dataLayer */
import React from 'react';
import './Cookie.css';

class Cookie extends React.Component {

    componentDidMount() {

    }
    cookieY = () => {
        // window['ga-disable-UA-148670956-10'] = false;
        // window.dataLayer = window.dataLayer || [];

        // function gtag() {
        //     dataLayer.push(arguments);
        // }

        // gtag('js', new Date());
        // gtag('config', 'UA-148670956-10', { 'anonymize_ip': true });

        this.props.onCookieChange();
    };
    cookieN = () => {
        // window['ga-disable-UA-148670956-10'] = true;
        this.props.onCookieChange();
    };
    render () {

        this.cookieY();
        return (
            null
            // <div className={'cookie'}>
            //     <div className={"cookie-container"}>
            //         <span>Dürfen wir Ihre Nutzung unserer Website zur Verbesserung unseres Angebots auswerten?</span>
            //         <p>
            //             Dafür nutzen wir Google Analytics. Weitere Informationen finden Sie in unserer
            //             Datenschutzerklärung.
            //         </p>
            //         <a onClick={()=>{this.cookieY()}}>Ja</a>
            //         <a onClick={()=>{this.cookieN()}}>Nein</a>
            //     </div>
            // </div>
        )
    }
}
export default Cookie;