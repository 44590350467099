/* globals content */

import React from 'react';
import QrReader from 'react-qr-reader'
import {getTargetName, loadTarget} from "../common/getTargetName";
import frame from '../assets/img/frame.png';
import scannerGIF from '../assets/img/ScannedOnce.gif'
import './QrCodeScanner.css';
//import content from "../common/content";

import {getQrURL} from '../common/qrreader'

class QrCodeScanner extends React.Component {

    constructor(props) {
        super(props);
    }
    state = {
        result: 'Scannen Sie den QR-Code des Inhalts den Sie ansehen wollen',
        alreadyActive: 'QR-Code ist bereits aktiv'
    };

    handleScan = data => {
        if (data) {

            let targetName = getTargetName('target', data);

            this.setState({
                //result: 'Target '+targetName+ ' found!'
            })


            const contentType = loadTarget(targetName);
            let currentContent = content[targetName];
            console.log(contentType)
            // this.props.onContentTypeChange(contentType, currentContent);
            // this.props.onChange();

        }
    }
    handleError = err => {
        console.error(err)
    }

    render() {
        return (
            <div id='qrCodeScanner' className={'QrCodeScanner'}>
                <img src={frame} alt={'frame'} className={'qr-code-frame'}/>
                {/* <img id='' alt={'scannerGIF'} className={'qr-code-frame'}/> */}
                {/* <QrReader
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%' }}
                /> */}
                <p>{this.state.result}</p>
            </div>
        )
    }
}

export default QrCodeScanner;