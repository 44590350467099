/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';

class FrontPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['front-page']) {
            AFRAME.registerComponent('front-page', {
                schema: {
                    pages:{type: 'number', default: 5}
                },
                init: function () {
                    this.clock = new THREE.Clock();

                    this.upPlanes = [];
                    for (let idx = 0; idx < this.data.pages; idx++) {
                        const element = document.getElementById('up-' + idx);
                        this.upPlanes.push(element);
                    }

                    this.plane = document.getElementById('up-01');

                    this.el.addEventListener('model-loaded', () => {
                        const obj = this.el.getObject3D('mesh');
                        this.animationMixer = new THREE.AnimationMixer(obj);
                        for (let idx = 0; idx < obj.animations.length; idx++) {
                            const animation = obj.animations[idx];
                            let action = this.animationMixer.clipAction(animation);
                            action.loop = THREE.LoopOnce;
                            action.clampWhenFinished = true;
                            action.timeScale = 2.0;
                            if (!this.animations) this.animations = [];
                            this.animations.push(action);
                        }

                        this.clickHandler = this.clickHandler.bind(this)
                        this.el.sceneEl.addEventListener('mousedown', this.clickHandler);
                    })
                    this.i = 0;
                    this.isRunning = false;
                },
                tick: function () {
                    if (this.animations) {
                        if (this.isRunning && this.animations[this.i].paused) {
                            ++this.i;
                            this.isRunning = false;
                        }
                    }
                    const delta = this.clock.getDelta();
                    if (this.animationMixer) this.animationMixer.update(delta);
                },

                clickHandler: function (e) {
                    let didReset = false;
                    if (this.i >= 5) {
                        for (let idx = 0; idx < this.animations.length; idx++) {
                            const animation = this.animations[idx];
                            animation.stop();
                        }
                        this.upPlanes[this.i - 1].click();
                        this.i = 0;
                        didReset = true;
                    }
                    else if (this.animations && !didReset) {
                        if (!this.isRunning) {
                            this.animations[this.i].play();
                            this.isRunning = true;
                            this.upPlanes[this.i].click();
                            if(this.i != 0) this.upPlanes[this.i - 1].click(); 
                            _satellite.track('content-clicked');
                        }
                    }
                }
            })
        }

        if (!AFRAME.components['put-up']) {
            AFRAME.registerComponent('put-up', {
                schema: {
                    textureURL:{type: "string"},
                    isLast: {type: 'boolean', default: false},
                },
                init: function () {
                    this.clock = new THREE.Clock();
                    this.el.addEventListener('model-loaded', () => {
                        const obj = this.el.getObject3D('mesh');
                        obj.traverse((child)=>{
                            if(child.isMesh) {
                                const textureLoader = new THREE.TextureLoader();
                                child.material.transparent = true;
                                textureLoader.load(this.data.textureURL, function(texture) {
                                    texture.flipY = false;
                                    child.material.map = texture;
                                    child.material.needsUpdate = true;
                                })
                                
                            }
                        })
                        this.animationMixer = new THREE.AnimationMixer(obj);
                        for (let idx = 0; idx < obj.animations.length; idx++) {
                            const animation = obj.animations[idx];
                            let action = this.animationMixer.clipAction(animation);
                            action.loop = THREE.LoopOnce;
                            action.clampWhenFinished = true;
                            action.timeScale = 2.0;
                            if (!this.animations) this.animations = [null, null];
                            if(idx == 0) {
                                this.animations[1] = action;
                            }
                            else {
                                this.animations[0] = action;
                            }
                        }

                        this.clickHandler = this.clickHandler.bind(this);
                        this.el.addEventListener('click',this.clickHandler);
                    })
                    this.i = 0;
                    this.isRunning = false;
                },
                tick: function () {

                    if (this.animations) {
                        if (this.isRunning && this.animations[this.i].paused) {
                            if(this.i == 1) this.el.setAttribute('visible', false);
                            ++this.i;
                            this.isRunning = false;
                        }
                    }
                    const delta = this.clock.getDelta();
                    if (this.animationMixer) this.animationMixer.update(delta);
                },
                clickHandler: function () {
                    if (this.i >= 2) {
                        for (let idx = 0; idx < this.animations.length; idx++) {
                            const animation = this.animations[idx];
                            animation.stop();
                        }
                        this.i = 0;
                    }
                    if (this.animations) {
                        if (!this.isRunning) {
                            this.animations[this.i].play();
                            this.isRunning = true;
                            if(this.i == 0) {this.el.setAttribute('visible', true);}
                            else if(this.data.isLast) {this.el.setAttribute('visible', false);}
                        }
                    }
                }
            })
        }

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'} id={'front-page-scene'} visible={'false'}>
                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.URL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'paper'} front-page={''} class={'cantap'} model-loading={"unhide: front-page-scene;"}
                    >
                    </a-entity>
                    <a-entity basic-mat={'setBasic: true;'}
                        gltf-model={this.state.currentContent.planeURL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'up-0'} put-up={' textureURL: ' +  this.state.currentContent.planeTextures[0] + ';' }
                        visible={false}
                    >
                    </a-entity>
                    <a-entity basic-mat={'setBasic: true;'}
                        gltf-model={this.state.currentContent.planeURL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'up-1'} put-up={' textureURL: ' +  this.state.currentContent.planeTextures[1] + ';' }
                        visible={false}
                    >
                    </a-entity>
                    <a-entity basic-mat={'setBasic: true;'}
                        gltf-model={this.state.currentContent.planeURL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'up-2'} put-up={' textureURL: ' +  this.state.currentContent.planeTextures[2] + ';' }
                        visible={false}
                    >
                    </a-entity>
                    <a-entity basic-mat={'setBasic: true;'}
                        gltf-model={this.state.currentContent.planeURL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'up-3'} put-up={' textureURL: ' +  this.state.currentContent.planeTextures[3] + ';' }
                        visible={false}
                    >
                    </a-entity>
                    <a-entity basic-mat={'setBasic: true;'}
                        gltf-model={this.state.currentContent.planeURL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        id={'up-4'} put-up={' textureURL: ' +  this.state.currentContent.planeTextures[4] + '; isLast: true;'}
                        visible={false}
                    >
                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }
                <a-image
                    id={'loading-spinner'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x + ' ' + this.state.currentContent.position.y + ' 0.003'}
                    visible={'true'}>
                </a-image>
            </a-entity>
        )
    }
}
export default FrontPage;
