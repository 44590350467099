
import React from 'react';
import arrowBack from '../assets/img/arrow-back.png';
import './QrCodeBtnBack.css';

class QrCodeBtnBack extends React.Component {


    render() {
        return (
            <div className={'QrCodeBtnBack'} onClick={this.props.onClick}>
                <img src={arrowBack} alt={'arrow-back'}/>
            </div>
        )
    }
}

export default QrCodeBtnBack;