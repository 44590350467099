/* globals AFRAME, anime, _satellite */
import * as THREE from 'three';

import {
    getTargetName
} from "../../common/getTargetName";

var currentDetailName = ''

AFRAME.registerComponent('hider-material', {
    init() {
        const hiderMaterial = new THREE.MeshStandardMaterial()
        hiderMaterial.colorWrite = false
  
        const applyHiderMaterial = (mesh) => {
          if (!mesh) { return }
          if (mesh.material) {
            mesh.material = hiderMaterial
          }
          mesh.traverse((node) => {
            if (node.isMesh) {
              node.material = hiderMaterial
            }
          })
        }
  
        applyHiderMaterial(this.el.getObject3D('mesh'))
        this.el.addEventListener(
          'model-loaded', () => applyHiderMaterial(this.el.getObject3D('mesh'))
        )
      },
});

AFRAME.registerComponent('target-video-no-sound', {
    schema: {
        name: {
            type: 'string'
        },
        video: {
            type: 'string'
        },
    },
    update: function () {
        const object3D = this.el.object3D;
        const name = this.data.name;
        object3D.visible = false;

        const v = document.querySelector(this.data.video);
        console.log('video', v.src);
        const el = this.el;

        const showImage = ({
            detail
        }) => {


            v.addEventListener("loadeddata", function () {
                //  object3D.visible = true;
            });

            if (v.src && name === detail.name) {
                v.play();
            }

            object3D.position.copy(detail.position);
            object3D.quaternion.copy(detail.rotation);
            object3D.scale.set(detail.scale, detail.scale, detail.scale);
            object3D.visible = true;
        }
        const hideImage = ({
            detail
        }) => {
            v.pause();
            object3D.visible = false
        }
        this.el.sceneEl.addEventListener('xrimagefound', showImage);
        this.el.sceneEl.addEventListener('xrimageupdated', showImage);
        this.el.sceneEl.addEventListener('xrimagelost', hideImage);
    }
});

AFRAME.registerComponent('image-target', {
    schema: {
        name: {
            type: 'string'
        }
    },
    init() {
        const {
            object3D
        } = this.el
        object3D.visible = false
        var {
            name
        } = this.data

        if (name === "undefined") name = getTargetName('target', window.location.href)

        const showImage = ({
            detail
        }) => {
            currentDetailName = detail.name
            if (name !== detail.name) return
            object3D.position.copy(detail.position)
            object3D.quaternion.copy(detail.rotation)
            object3D.scale.set(detail.scale, detail.scale, detail.scale)
            object3D.visible = true
        }

        const hideImage = ({
            detail
        }) => {
            currentDetailName = ''
            if (name !== detail.name) return
            object3D.visible = false
            document.querySelector('#video-mp4').pause()
        }

        this.el.sceneEl.addEventListener('xrimageupdated', showImage)
        this.el.sceneEl.addEventListener('xrimagelost', hideImage)
    },
});

AFRAME.registerComponent('has-audio', {
    schema: {
        hasAudio: {
            type: 'string'
        }
    },
    init() {
        if (this.data.hasAudio === 'true') {
            this.el.setAttribute('visible', 'true')
            this.el.classList.add('cantap')
        } else {
            this.el.setAttribute('visible', 'false')
        }
    }
});

AFRAME.registerComponent('target-video', {
    schema: {
        urlVideo: {
            type: 'string'
        },
        hasChroma: {
            type: 'string'
        },
        canstop: {
            type: 'bool'
        },
    },
    init() {

        const vid = document.getElementById('video-mp4')
        vid.src = this.data.urlVideo
        // vid.setAttribute('loop', 'false')
        vid.setAttribute('crossOrigin', 'anonymous')
        vid.load()
        vid.muted = true;

        this.video = vid;
        this.currentTime = this.video.currentTime;

        const {
            el
        } = this


        el.setAttribute('material', 'src', vid)
        if (this.data.hasChroma === 'true') {
            el.setAttribute('material', 'shader', 'chromakey')
            el.setAttribute('material', 'color', '0.1 0.9 0.2')
        }
        el.setAttribute('class', 'cantap')

        var isPlaying = false


        this.playbutton = document.getElementById('playBtn')

        const self = this;
        const play = () => {
            if (!isPlaying) {
                if(vid.currentTime <= 0.1) _satellite.track("Media - Video Started")
                if(vid.muted) vid.muted = false;
                vid.play()
                console.log('pressed play')
                self.playbutton.object3D.visible = false
                isPlaying = true
            } else if (this.data.canstop) {
                vid.pause()
            }
        }

        // el.addEventListener('click', play)
        this.playbutton.addEventListener('click', play)

        this.handleEnded = this.handleEnded.bind(this)
        this.isPlaying = isPlaying;
        vid.addEventListener('ended', this.handleEnded);

        vid.addEventListener('pause', () => {
            isPlaying = false
            self.playbutton.object3D.visible = true
        })


        this.handleLoadeddata = this.handleLoadeddata.bind(this);
        this.video.addEventListener('loadeddata', this.handleLoadeddata);

        this.handleCanPlayThrough = this.handleCanPlayThrough.bind(this);
        this.video.addEventListener('canplaythrough', this.handleCanPlayThrough);
    },

    remove: function () {
        this.video.removeEventListener('ended', this.handleEnded);
        this.video.removeEventListener('loadeddata', this.handleLoadeddata);
        this.video.removeEventListener('canplaythrough', this.handleCanPlayThrough);
      },
  
    tick() {
        // console.log(this.video.currentTime)
        this.currentTime = this.video.currentTime

        if(!this.canPlay && this.playbutton) {
            this.playbutton.object3D.rotation.z -= Math.PI / 64;
        }
        else if(this.playbutton.object3D.rotation.z != 0) {
            this.playbutton.object3D.rotation.z = 0;
        }
        
    },
    handleEnded () {
        console.log('video finished')
            _satellite.track("Media - Video Completed")
            if (!this.video.loop) {
                this.isPlaying = false
                this.video.currentTime = 0
            }
    },
    handleLoadeddata () {
        console.log('video '+ this.data.urlVideo +' loaded')
        this.video.play()
        setTimeout(()=>{
            this.video.pause();
            this.video.currentTime = 0;
            this.video.muted = false;
        },100)

    },
    handleCanPlayThrough: function(e) {
        this.canPlay = true;
        this.playbutton.setAttribute('class', 'cantap')
        this.playbutton.setAttribute('src', '#play-btn')
        this.playbutton.object3D.rotation.z = 0;
    },
});

AFRAME.registerComponent('button-animation', {
    schema: {
        scale: {type: 'number', default: 0.2},
        time: {type: 'number', default: 100}
    },
    init() {
        const playAnimation = () => {
            var t = {
                'posX': 0,
                'posY': 0,
            }
            var isAlreadyPlaying = false
            for (let i = 0; i < anime.running.length; i++) {
                if (anime.running[i].animatables[0].target) {
                    isAlreadyPlaying = true
                    break
                }
            }
            if (!isAlreadyPlaying) {
                var xyz = this.el.object3D.scale.clone()
                var self = this
                anime({
                    targets: t,
                    posY: this.data.scale,
                    loop: 4,
                    duration: this.data.time,
                    direction: 'alternate',
                    easing: 'easeInOutSine',
                    update: function () {
                        self.el.object3D.scale.x = xyz.x + t.posY
                        self.el.object3D.scale.y = xyz.y + t.posY
                        // self.el.object3D.scale.z = xyz.z + t.posY
                    }
                })
            }
        }

        this.el.addEventListener('click', playAnimation);
    }
});

AFRAME.registerComponent('one-finger-rotate', {
    schema: {
        factor: {type: 'number', default: 6},
      },
      init() {
        this.handleEvent = this.handleEvent.bind(this)
        this.el.sceneEl.addEventListener('onefingermove', this.handleEvent)
        this.el.classList.add('cantap')  // Needs "objects: .cantap" attribute on raycaster.
      },
      remove() {
        this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
      },
      handleEvent(event) {
        this.el.object3D.rotation.y += (event.detail.positionChange.x * this.data.factor);
      },
});


AFRAME.registerComponent('portal-video', {
    schema: {
        urlVideo: {
            type: 'string'
        },
    },
    init: function () {
        const vid = document.getElementById('video-mp4')
        vid.src = this.data.urlVideo
        // vid.setAttribute('loop', 'false')
        vid.setAttribute('crossOrigin', 'anonymous')
        vid.load()
        vid.muted = true;
        vid.loop = true;

        this.video = vid;

        this.el.setAttribute('material', 'src', vid);

        this.handleLoadeddata = this.handleLoadeddata.bind(this);
        this.video.addEventListener('loadeddata', this.handleLoadeddata);
    },

    remove: function () {
        this.video.loop = false;
        this.video.muted = false;
        this.video.removeEventListener('loadeddata', this.handleLoadeddata);
    },


    tick() {
        if(this.video && this.video.paused) this.video.play();
    },

    handleLoadeddata () {
        console.log('video '+ this.data.urlVideo +' loaded');
        const video = this.video;
        setTimeout(()=>{video.play()}, 200);
    }
});

AFRAME.registerComponent('model-loading', {
    schema: {
        unhide: {
            type: 'string'
        },
    },
    init : function() {
        this.unhideEl = document.getElementById(this.data.unhide);
        this.loadingSpinnerEl = document.getElementById('loading-spinner');
        this.isLoading = true;

        this.modelLoadedHandler = this.modelLoadedHandler.bind(this);
        this.el.addEventListener('model-loaded', this.modelLoadedHandler)
    }, 
    tick: function() {
        if(this.isLoading && this.loadingSpinnerEl) {
            this.loadingSpinnerEl.object3D.rotation.z -= Math.PI / 64;
        }
    },
    remove: function() {
        this.el.removeEventListener('model-loaded', this.modelLoadedHandler)
    },

    modelLoadedHandler: function() {
        this.loadingSpinnerEl.setAttribute('visible', false);
        this.unhideEl.setAttribute('visible', true);
        this.isLoading = false;
    }
});



const getCurrentDetailName = () => { return currentDetailName }

export {getCurrentDetailName}