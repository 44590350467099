/* globals AFRAME, digitalData, _satellite */
import React from 'react';

class ModelGLTF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D'

        digitalData.media = media
        _satellite.track("Media Scanned")

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'}>
                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial} one-finger-rotate={"factor: 6"}
                        gltf-model={this.state.currentContent.URL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z }
                        rotate-local={'direction: ' + this.state.currentContent.rotationAxis + '; speed: ' + this.state.currentContent.rotationSpeed + ';'}
                        id={'modelGLTF'} animation-mixer visible={'false'} model-loading={'unhide: modelGLTF'}>
                    </a-entity>
                </a-entity>
                <a-image 
                    id={'loading-spinner'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x+ ' '+this.state.currentContent.position.y+' 0.003'}
                    visible={'true'}>
                </a-image>
            </a-entity>
        )
    }
}
export default ModelGLTF;
