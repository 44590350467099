/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three'
import RAPIER from "@dimforge/rapier3d-compat";
import { toBeInTheDOM } from '@testing-library/jest-dom';

class BuildingBlocks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        }

        if (!AFRAME.components['physics-scene']) {
            AFRAME.registerComponent('physics-scene', {
                schema: {
                    cubes: { type: 'number', default: 8 },
                    cubeSize: { type: 'number', default: 0.25 },
                    factor: { type: 'number', default: 6 }
                },

                init: function () {
                    this.camera = document.getElementById('camera');
                    this.globalGravity = new RAPIER.Vector3(0, -9.81, 0);

                    this.raycaster = new THREE.Raycaster();

                    const cubeModelEl = document.getElementById('cube-model');
                    cubeModelEl.addEventListener('model-loaded', () => {
                        const obj = cubeModelEl.object3D;
                        this.cubeModel = obj;

                        this.cubes = [];
                        for (let i = 0; i < this.data.cubes; i++) {
                            let newEl = document.createElement('a-box');
                            newEl.setAttribute('width', this.data.cubeSize);
                            newEl.setAttribute('height', this.data.cubeSize);
                            newEl.setAttribute('depth', this.data.cubeSize);
                            this.el.appendChild(newEl);
                            newEl.setAttribute('position', (this.data.cubeSize + 0.1 * Math.random()) + ' ' + (i * (this.data.cubeSize + 0.1) + 1) + ' ' +  (this.data.cubeSize + 0.1 * Math.random()));
                            newEl.classList.add('cantap');
                            newEl.name = "cube-" + i;

                            const newCubeModel = this.cubeModel.clone();
                            newEl.object3D.add(newCubeModel);
                            newCubeModel.scale.set(0.109,0.109,0.109);
                            newCubeModel.rotation.x = getRandomInt(0,3) * Math.PI / 2;
                            newCubeModel.rotation.y = getRandomInt(0,3) * Math.PI / 2;
                            newCubeModel.rotation.z = getRandomInt(0,3) * Math.PI / 2;
                            this.cubes.push(newEl.object3D);
                        }

                        this.startSimulation = this.startSimulation.bind(this);
                        RAPIER.init().then(this.startSimulation)
                    })


                },
                fingerdown: function (e) {
                    if (e.detail.intersection) {
                        this.selectedObject = e.detail.intersection.object.parent;
                        this.distanceToSelected = e.detail.intersection.distance;
                        _satellite.track('content-clicked');
                    }
                },

                fingerup: function () {
                    if (this.selectedObject) {
                        this.selectedObject = null;
                        this.distanceToSelected = null;
                        this.positionRaw = null;
                    }
                },

                fingermove: function (e) {
                    if (this.selectedObject) {
                        this.positionRaw = e.detail.positionRaw;
                    }
                },

                activeMoveRigidBody: function () {
                    if (this.selectedObject && this.positionRaw && this.distanceToSelected) {

                        const screenPositionX = this.positionRaw.x / document.body.clientWidth * 2 - 1;
                        const screenPositionY = this.positionRaw.y / document.body.clientHeight * 2 - 1;
                        const screenPosition = new THREE.Vector2(screenPositionX, -screenPositionY);

                        this.threeCamera = this.threeCamera || this.camera.getObject3D('camera');
                        this.raycaster.setFromCamera(screenPosition, this.threeCamera);

                        let newPosition = this.raycaster.ray.origin.clone().addScaledVector(
                            this.raycaster.ray.direction, this.distanceToSelected
                        );
                        newPosition = this.el.object3D.worldToLocal(newPosition);
                        if(newPosition.y < this.data.cubeSize / 2) newPosition.y = this.data.cubeSize / 2;
                        
                        let newRigidBodyPosition = this.selectedObject.position.clone();
                        newRigidBodyPosition.lerp(newPosition, 0.3);
                        this.selectedObject.rigidBody.setTranslation(
                            {
                                x: newRigidBodyPosition.x,
                                y: newRigidBodyPosition.y,
                                z: newRigidBodyPosition.z
                            }, true);
                        this.selectedObject.rigidBody.setLinvel({ x: 0.0, y: 0.0, z: 0.0 }, true);
                    }
                },

                remove: function () {
                    this.el.removeEventListener('mousedown', this.fingerdown);
                    this.el.sceneEl.removeEventListener('onefingerend', this.fingerup)
                    this.el.sceneEl.removeEventListener('onefingermove', this.fingermove)
                },

                tick: function (time, timeDelta) {
                    if (this.physicsScene) {
                        this.physicsScene.step();
                        if (this.selectedObject) {
                            this.activeMoveRigidBody()
                        }
                        this.updateDynamics();
                    }
                },
                startSimulation: function () {
                    this.physicsScene = new RAPIER.World(this.globalGravity);
                    this.setupPlatform();
                    this.setupDynamics();
                },

                setupDynamics: function () {
                    const cubeMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity:0 });
                    for (let i = 0; i < this.cubes.length; i++) {
                        let currentCube = this.cubes[i];
                        for (let j = 0; j < currentCube.children.length; j++) {
                            let child = currentCube.children[j];
                            if (child.material) child.material = cubeMaterial;
                        }
                        const rigidBodyDesc = RAPIER.RigidBodyDesc.dynamic()
                            .setTranslation(
                                currentCube.position.x,
                                currentCube.position.y,
                                currentCube.position.z
                            )

                        currentCube.rigidBody = this.physicsScene.createRigidBody(rigidBodyDesc);
                        const colliderDesc = RAPIER.ColliderDesc.cuboid(this.data.cubeSize / 2, this.data.cubeSize / 2, this.data.cubeSize / 2).setDensity(50);
                        const collider = this.physicsScene.createCollider(colliderDesc, currentCube.rigidBody);

                    }
                    this.fingerdown = this.fingerdown.bind(this);
                    this.el.addEventListener('mousedown', this.fingerdown);
                    this.fingerup = this.fingerup.bind(this);
                    this.el.sceneEl.addEventListener('onefingerend', this.fingerup);
                    this.fingermove = this.fingermove.bind(this);
                    this.el.sceneEl.addEventListener('onefingermove', this.fingermove);
                },
                setupPlatform: function () {
                    this.pivotPoint = new THREE.Vector3(0, -0.2, 0)

                    const rigidBodyDesc = RAPIER.RigidBodyDesc.fixed()
                        .setTranslation(
                            this.pivotPoint.x,
                            this.pivotPoint.y,
                            this.pivotPoint.z
                        ).setCcdEnabled(true);
                    const rigidBody = this.physicsScene.createRigidBody(rigidBodyDesc);

                    // // Ground
                    // this.ground = new THREE.Mesh(
                    //     new THREE.BoxGeometry(10, 0.4, 10),
                    //     new THREE.MeshPhongMaterial({ color: 0xff00ff })
                    // )
                    // this.el.object3D.add(this.ground)
                    // this.ground.position.y -= 0.2;

                    const colliderDesc = RAPIER.ColliderDesc.cuboid(
                        5, 0.2, 5
                    );
                    this.physicsScene.createCollider(colliderDesc, rigidBody);
                },
                updateDynamics: function () {
                    for (let i = 0; i < this.cubes.length; ++i) {
                        let currentCube = this.cubes[i];
                        var position = currentCube.rigidBody.translation();
                        const rotation = currentCube.rigidBody.rotation();
                        const linvel = currentCube.rigidBody.linvel();

                        currentCube.position.x = position.x;
                        currentCube.position.y = position.y;
                        currentCube.position.z = position.z;

                        currentCube.setRotationFromQuaternion(
                            new THREE.Quaternion(
                                rotation.x,
                                rotation.y,
                                rotation.z,
                                rotation.w
                            )
                        );
                    }
                },
            });
        }


    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity 
                    scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                    rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                    position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                    physics-scene={'cubes: ' + this.state.currentContent.cubes + ';'} class={'cantap'}>
                    <a-entity gltf-model={this.state.currentContent.URL}
                        basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        scale={'0 0 0'}
                        id={'cube-model'}></a-entity>
                </a-entity>

                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }

            </a-entity>
        )
    }
}
export default BuildingBlocks;
