/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import './registerComponent';

class VideoWithSound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent,
            playVideoWithPoster: props.playVideoWithPoster
        };
    }

    componentDidMount() {
        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = 'Video'
        if(this.state.currentContent.targetName.substring(0,3) === 'ANZ') media.type = 'Anzeige mit Video'

        digitalData.media = media
        _satellite.track("Media Scanned")
    }

    render() {
        const playVideoWithPoster = this.state.playVideoWithPoster;
        let poster = this.state.currentContent.poster;
        let sceneRot = '0 0 0'
        if(this.state.currentContent.targetName.substring(0,7) === "ANZ_DHL") sceneRot = '0 0 -90'
        return (
            <a-entity rotation={sceneRot}>
                <a-entity 
                    id="videoEntity"
                    position={this.state.currentContent.position.x+ ' '+this.state.currentContent.position.y+' 0'}
                    geometry={"primitive: plane; height: " + this.state.currentContent.size.h + "; width: " + this.state.currentContent.size.w + "; buffer: false; "}
                    target-video= {
                        'urlVideo: ' + this.state.currentContent.URL + '; ' + 
                        'canstop: true; ' +
                        'hasChroma: ' + this.state.currentContent.hasChroma + '; '
                    } 
                ></a-entity>
                <a-image 
                    id={'playBtn'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x+ ' '+this.state.currentContent.position.y+' 0.003'}>
                </a-image>
            </a-entity>
            
            // <a-entity
            //     class={'cantap'}
            //     id={"headEl"}
            //     target-video={'name: ' + this.state.currentContent.targetName + '; video: #videoFile; poster: ' +this.state.currentContent.poster+ '; playVideoWithPoster: '+playVideoWithPoster+''}>


            //     <a-entity id={"videoObj"}
            //               material={""}
            //               geometry={"primitive: plane; height: " + this.state.currentContent.size.h + "; width: " + this.state.currentContent.size.w + "; buffer: false; "}
            //               position={this.state.currentContent.position.x+ ' '+this.state.currentContent.position.y+' 0'}
            //               rotation={"0 0 0"}>
            //     </a-entity>

 

            //     {this.state.currentContent.text &&
            //     <a-entity geometry={"primitive: plane; width: "+this.state.currentContent.size.w+"; height: auto"}
            //               material={"color: #110a35"}
            //               position={this.state.currentContent.position.x +' -'+ (this.state.currentContent.size.h/2)+0.05 +' 0.007'}
            //               text={'value:'+this.state.currentContent.text+'; color: white; width: '+(this.state.currentContent.size.w-0.08)+'; height: auto'}>
            //     </a-entity>}
            //     <a-plane height={this.state.currentContent.size.h} width={this.state.currentContent.size.w} position={'0 0 -0.001'} hider-material></a-plane>
            // </a-entity>
        )
    }
}

export default VideoWithSound;