/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';

class Animals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {
        // console.log(this.state.currentContent)

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['pivot-around']) {
            AFRAME.registerComponent('pivot-around', {
                schema: {
                    factor: {type: 'number', default: 4},
                },
            
                init: function () {
                    // this.pivotEl = document.getElementById('pivot-el');
                    this.object3D = this.el.object3D;

                    this.handleMove = this.handleMove.bind(this);
                    this.el.sceneEl.addEventListener('onefingermove', this.handleMove)
                },
            
                remove: function () {
                    this.el.sceneEl.removeEventListener('onefingermove', this.handleMove)
                },
            
                tick: function (time, timeDelta) {
                //   const currentPos = this.object3D.getWorldPosition(new THREE.Vector3());
                //   const distanceToCenter = currentPos.distanceTo(this.pivotEl.object3D.position);
                //   console.log(distanceToCenter);
                },
                handleMove: function(event) {
                    this.object3D.rotation.y += (event.detail.positionChange.x * this.data.factor);
                }
            });
            
        }

        if (!AFRAME.components['scale-on-distance']) {
            AFRAME.registerComponent('scale-on-distance', {
                schema:{
                    offset: {type: 'number'},
                    scale: {type: "number"}
                },
                init: function () {
                    this.object3D = this.el.object3D;
                    this.pivotEl = document.getElementById('mainObject3D');

                },
                tick: function (time, timeDelta) {
                  const currentPos = this.object3D.getWorldPosition(new THREE.Vector3());
                  var pivotPos = this.pivotEl.object3D.getWorldPosition(new THREE.Vector3());
                  pivotPos.z -= this.data.offset;
                  const distanceToCenter = currentPos.distanceTo(pivotPos);
                  var invLerp = (distanceToCenter - 2.1) / (1.5 - 2.1 );
                  if(invLerp > 1) invLerp = 1;
                  else if(invLerp < 0) invLerp = 0;
                  this.el.object3D.scale.x = this.data.scale * invLerp;
                  this.el.object3D.scale.y = this.data.scale * invLerp;
                  this.el.object3D.scale.z = this.data.scale * invLerp;
                },
            });
            
        }

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'}>

                    <a-entity id={'pivot-el'}
                        pivot-around={"factor: 4;"}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                    >

                        <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            class={"cantap"} 
                            scale-on-distance={'offset:' + this.state.currentContent.position.z + ' ;scale: ' + this.state.currentContent.animals[0].scale + ';'}
                            gltf-model={this.state.currentContent.animals[0].URL}
                            rotation={this.state.currentContent.animals[0].rotation.x + " " + this.state.currentContent.animals[0].rotation.y + " " + this.state.currentContent.animals[0].rotation.z}
                            scale={this.state.currentContent.animals[0].scale + "  " + this.state.currentContent.animals[0].scale + " " + this.state.currentContent.animals[0].scale}
                            position={this.state.currentContent.animals[0].position.x + " 0 " + this.state.currentContent.animals[0].position.y}
                            id={'a1'}>
                                <a-plane 
                                width='1' height='1' 
                                src={this.state.currentContent.animals[0].imageURL} 
                                transparent='true'
                                position='0 -0.01 0'
                                rotation='-90 0 0'
                                scale='2.3 2.3 2.3'>  
                                </a-plane>
                        </a-entity>
                        <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            class={"cantap"} 
                            scale-on-distance={'offset:' + this.state.currentContent.position.z + ' ;scale: ' + this.state.currentContent.animals[1].scale + ';'}
                            gltf-model={this.state.currentContent.animals[1].URL}
                            rotation={this.state.currentContent.animals[1].rotation.x + " " + this.state.currentContent.animals[1].rotation.y + " " + this.state.currentContent.animals[1].rotation.z}
                            scale={this.state.currentContent.animals[1].scale + "  " + this.state.currentContent.animals[1].scale + " " + this.state.currentContent.animals[1].scale}
                            position={this.state.currentContent.animals[1].position.x + " 0 " + this.state.currentContent.animals[1].position.y}
                            id={'a2'}>
                                 <a-plane 
                                width='1' height='1' 
                                src={this.state.currentContent.animals[1].imageURL} 
                                transparent='true'
                                position='0 -0.01 0'
                                rotation='-90 0 0'
                                scale='2.3 2.3 2.3'>  
                                </a-plane>
                        </a-entity>
                        <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            class={"cantap"} 
                            scale-on-distance={'offset:' + this.state.currentContent.position.z + ' ;scale: ' + this.state.currentContent.animals[2].scale + ';'}
                            gltf-model={this.state.currentContent.animals[2].URL}
                            rotation={this.state.currentContent.animals[2].rotation.x + " " + this.state.currentContent.animals[2].rotation.y + " " + this.state.currentContent.animals[2].rotation.z}
                            scale={this.state.currentContent.animals[2].scale + "  " + this.state.currentContent.animals[2].scale + " " + this.state.currentContent.animals[2].scale}
                            position={this.state.currentContent.animals[2].position.x + " 0 " + this.state.currentContent.animals[2].position.y}
                            id={'a3'}>
                                 <a-plane 
                                width='1' height='1' 
                                src={this.state.currentContent.animals[2].imageURL} 
                                transparent='true'
                                position='0 -0.01 0'
                                rotation='-90 0 0'
                                scale='2.3 2.3 2.3'>  
                                </a-plane>
                        </a-entity>
                        <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            class={"cantap"} 
                            scale-on-distance={'offset:' + this.state.currentContent.position.z + ' ;scale: ' + this.state.currentContent.animals[3].scale + ';'}
                            gltf-model={this.state.currentContent.animals[3].URL}
                            rotation={this.state.currentContent.animals[3].rotation.x + " " + this.state.currentContent.animals[3].rotation.y + " " + this.state.currentContent.animals[3].rotation.z}
                            scale={this.state.currentContent.animals[3].scale + "  " + this.state.currentContent.animals[3].scale + " " + this.state.currentContent.animals[3].scale}
                            position={this.state.currentContent.animals[3].position.x + " 0 " + this.state.currentContent.animals[3].position.y}
                            id={'a4'}>
                                 <a-plane 
                                width='1' height='1' 
                                src={this.state.currentContent.animals[3].imageURL} 
                                transparent='true'
                                position='0 -0.01 0'
                                rotation='-90 0 0'
                                scale='2.3 2.3 2.3'>  
                                </a-plane>
                        </a-entity>
                        <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                            class={"cantap"} 
                            scale-on-distance={'offset:' + this.state.currentContent.position.z + ' ;scale: ' + this.state.currentContent.animals[4].scale + ';'}
                            gltf-model={this.state.currentContent.animals[4].URL}
                            rotation={this.state.currentContent.animals[4].rotation.x + " " + this.state.currentContent.animals[4].rotation.y + " " + this.state.currentContent.animals[4].rotation.z}
                            scale={this.state.currentContent.animals[4].scale + "  " + this.state.currentContent.animals[4].scale + " " + this.state.currentContent.animals[4].scale}
                            position={this.state.currentContent.animals[4].position.x + " 0 " + this.state.currentContent.animals[4].position.y}
                            id={'a5'}>
                                 <a-plane 
                                width='1' height='1' 
                                src={this.state.currentContent.animals[4].imageURL} 
                                transparent='true'
                                position='0 -0.01 0'
                                rotation='-90 0 0'
                                scale='2.3 2.3 2.3'>  
                                </a-plane>
                        </a-entity>
                    </a-entity>
                </a-entity>
            </a-entity>
        )
    }
}
export default Animals;
