/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three';
import * as UTILS from '../FunctionLibrary.js';

class Globe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = {id:'',title:'',type:''}
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['weather-data-trigger']) {
            AFRAME.registerComponent('weather-data-trigger', {
                schema: {
                    imageURL: {
                        type: 'string'
                    },
                    active: {
                        default: false
                    }
                },
                init: function () {
                    this.globe = document.getElementById('globe-el');
                    this.allTriggers = [];

                    this.activeColor = new THREE.Color('rgb(184,36,13)');
                    this.inactiveColor = new THREE.Color('rgb(255,255,255)');

                    // const self = this;
                    // UTILS.crop(self.data.imageURL, 1100, 764).then((canvas) => {
                    //     self.texture = new THREE.CanvasTexture(canvas);
                    //     self.texture.flipY = false;

                    //     if (self.data.active) {
                    //         this.clickHandler();
                    //     }

                    //     self.clickHandler = self.clickHandler.bind(self);
                    //     self.el.addEventListener('click', self.clickHandler);

                    //     self.el.setAttribute('visible', true);
                    // });


                    this.texture = new THREE.TextureLoader().load(this.data.imageURL);
                    this.texture.encoding = THREE.sRGBEncoding;
                    this.texture.flipY = false;


                    if (this.data.active) {
                        this.clickHandler();
                    }

                    this.clickHandler = this.clickHandler.bind(this);
                    this.el.addEventListener('click', this.clickHandler);

                    this.el.setAttribute('visible', true);

                    // if(this.data.active) {
                    //     this.clickHandler();
                    // }

                    // this.clickHandler = this.clickHandler.bind(this);
                    // this.el.addEventListener('click', this.clickHandler);
                },
                remove: function () {
                    this.el.removeEventListener('click', this.clickHandler);
                },
                clickHandler() {
                    const self = this;
                    self.globe.setAttribute('visible', true);
                    this.globe.object3D.traverse((child) => {
                        if (child.isMesh) {
                            child.material.map = self.texture;
                            _satellite.track("Media - " + self.el.id + ' selected');
                        }
                    })
                    if (self.allTriggers.length < 1) self.allTriggers = document.getElementsByClassName("weather-trigger");
                    for (let i = 0; i < this.allTriggers.length; i++) {
                        const element = this.allTriggers[i];
                        const isCurrentEl = element === this.el;
                        element.object3D.traverse((child) => {
                            if (child.isMesh) {
                                if (isCurrentEl) {
                                    child.material.color = self.activeColor;
                                }
                                else {
                                    child.material.color = self.inactiveColor;
                                }
                            }
                        })
                    }
                }
            });

        }

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity
                    rotation={'90 0 0'}
                    position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " 0.3"}
                    scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                >
                    <a-entity
                        rotation='-45 0 0'
                        position='0.1 0 0'
                        scale='1.2 1.2 1.2'
                    >
                        <a-entity
                            id={'freiheit-weltweit'}
                            visible='false'
                            class={'weather-trigger cantap'}
                            geometry="primitive: plane; width: 1; height: 0.14"
                            material={"src: " + this.state.currentContent.triggers[0].imageURL + "; side: double; transparent: true; shader: flat;"}
                            scale='0.8 0.8 0.8'
                            position={'-0.975 0.1 0'}
                            weather-data-trigger={'imageURL:' + this.state.currentContent.triggers[0].contentURL + '; active: true'}
                        ></a-entity>
                        <a-entity
                            id={'verschlechterung'}
                            visible='false'
                            class={'weather-trigger cantap'}
                            geometry="primitive: plane; width: 1; height: 0.14"
                            material={"src: " + this.state.currentContent.triggers[1].imageURL + "; side: double; transparent: true; shader: flat;"}
                            scale='0.8 0.8 0.8'
                            position={'-1 0 0'}
                            weather-data-trigger={'imageURL:' + this.state.currentContent.triggers[1].contentURL + '; active: false'}
                        ></a-entity>
                        <a-entity
                            id={'verbesserung'}
                            visible='false'
                            class={'weather-trigger cantap'}
                            geometry="primitive: plane; width: 1; height: 0.14"
                            material={"src: " + this.state.currentContent.triggers[2].imageURL + "; side: double; transparent: true; shader: flat;"}
                            scale='0.8 0.8 0.8'
                            position={'-0.975 -0.1 0'}
                            weather-data-trigger={'imageURL:' + this.state.currentContent.triggers[2].contentURL + '; active: false'}
                        ></a-entity>
                    </a-entity>
                    <a-entity one-finger-rotate={"factor: 6"}
                        class="cantap"
                        gltf-model={this.state.currentContent.modelURL}
                        basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        scale='0.5 0.5 0.5'
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z }
                        rotate-local={'direction: ' + this.state.currentContent.rotationAxis + '; speed: ' + this.state.currentContent.rotationSpeed + ';'}
                        id={'globe-el'}
                        visible='true'>
                    </a-entity>

                </a-entity>
            </a-entity>
        )
    }
}
export default Globe;
