/* globals AFRAME, digitalData, _satellite */
import React from 'react';
import * as THREE from 'three'
class Synthesizer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: props.currentContent
        };
    }
    componentDidMount() {

        let media = { id: '', title: '', type: '' }
        media.id = this.state.currentContent.targetName
        media.title = this.state.currentContent.targetName
        media.type = '3D Interaktiv'

        digitalData.media = media
        _satellite.track("Media Scanned")

        if (!AFRAME.components['synth']) {
            AFRAME.registerComponent('synth', {
                schema: {
                    sounds: { type: "string" },
                },

                init: function () {
                    this.threeCamera = document.getElementById("camera").getObject3D("camera");
                    const listener = new THREE.AudioListener();
                    const audioLoader = new THREE.AudioLoader();
                    this.currentState = {
                        isFingerDown: false,
                        previousObject: null,
                        intersectedObject: null,
                        raycaster: new THREE.Raycaster,
                        sounds: []
                    }
                    this.el.addEventListener('model-loaded', () => {
                        const obj = this.el.getObject3D('mesh');
                        obj.traverse(child => {
                            if (child.isMesh) {
                                child.userData.isSoundActive = false
                            }
                        })
                        for (let i = 0; i < 17; i++) {
                            let sound = new THREE.Audio(listener);
                            audioLoader.load(this.data.sounds + (i + 1) + '.mp3', function (buffer) {
                                sound.setBuffer(buffer);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                            })
                            this.currentState.sounds.push(sound);
                        }
                        this.clickHandler = this.clickHandler.bind(this);
                        this.el.addEventListener('mousedown', this.clickHandler, false);
                        this.fingerUpHandler = this.fingerUpHandler.bind(this);
                        this.el.sceneEl.addEventListener("onefingerend", this.fingerUpHandler);
                        this.fingerMoveHandler = this.fingerMoveHandler.bind(this);
                        this.el.sceneEl.addEventListener('onefingermove', this.fingerMoveHandler);
                    })
                },
                tick: function () {
                    if (this.currentState.isFingerDown) {
                        if (this.currentState.positionRaw) {
                            const pointer = new THREE.Vector2(
                                this.currentState.positionRaw.x / document.body.clientWidth * 2 - 1,
                                -(this.currentState.positionRaw.y / document.body.clientHeight * 2 - 1)
                            )
                            this.currentState.raycaster.setFromCamera(pointer, this.threeCamera);
                            const intersections = this.currentState.raycaster.intersectObject(this.el.object3D);
                            for (let i = 0; i < intersections.length; i++) {
                                this.currentState.intersectedObject = intersections[i].object;
                                break;
                            }
                            this.currentState.positionRaw = null;
                        }
                        if (this.currentState.intersectedObject) {
                            if (this.currentState.previousObject !== this.currentState.intersectedObject) {
                                if (this.currentState.previousObject && this.currentState.previousObject.name.length === 2) {
                                    this.currentState.previousObject.rotation.x = 0
                                    this.currentState.previousObject.userData.isSoundActive = false;
                                }
                            }
                            if (this.currentState.intersectedObject.name.length === 2) {
                                this.currentState.intersectedObject.rotation.x = 0.08;
                                if (!this.currentState.intersectedObject.userData.isSoundActive) {
                                    this.currentState.intersectedObject.userData.isSoundActive = true;
                                    const idx = parseInt(this.currentState.intersectedObject.name, 10);
                                    if (this.currentState.sounds[idx].isPlaying) this.currentState.sounds[idx].stop();
                                    this.currentState.sounds[idx].play();
                                    _satellite.track('content-clicked');
                                }
                            }
                            this.currentState.previousObject = this.currentState.intersectedObject;
                        }
                    }
                    else {
                        if (this.currentState.previousObject) {
                            this.currentState.previousObject.rotation.x = 0;
                            this.currentState.previousObject.userData.isSoundActive = false;
                            this.currentState.previousObject = null;
                        }
                    }
                },
                clickHandler: function (e) {
                    this.currentState.isFingerDown = true;
                    if (e.detail.intersection) {
                        this.currentState.intersectedObject = e.detail.intersection.object;
                    }
                },
                fingerUpHandler: function () {
                    this.currentState.intersectedObject = null;
                    this.currentState.isFingerDown = false;
                },
                fingerMoveHandler: function (e) {
                    this.currentState.positionRaw = e.detail.positionRaw;
                },
                remove: function () {
                    document.removeEventListener('mousedown', this.clickHandler);
                    this.el.sceneEl.removeEventListener('onefingerend', this.fingerUpHandler);
                    this.el.sceneEl.removeEventListener('onefingermove', this.fingerMoveHandler);
                }

            });
        }

    }

    render() {
        return (
            <a-entity>
                {/* <a-light type="ambient" intensity="1.5" color="#87CEEB"></a-light> */}
                {/* <a-entity light="type: directional; color: #fff; intensity: 1.5" position="-1 1 1"></a-entity> */}
                {/* <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 1"></a-entity> */}
                <a-entity rotation={'90 0 0'}>
                    <a-entity basic-mat={'setBasic: ' + this.state.currentContent.useBasicMaterial}
                        gltf-model={this.state.currentContent.URL}
                        position={this.state.currentContent.position.x + "  " + this.state.currentContent.position.y + " " + this.state.currentContent.position.z}
                        scale={this.state.currentContent.scale.x + "  " + this.state.currentContent.scale.y + " " + this.state.currentContent.scale.z}
                        rotation={this.state.currentContent.rotation.x + " " + this.state.currentContent.rotation.y + " " + this.state.currentContent.rotation.z}
                        rotate-local={'direction: ' + this.state.currentContent.rotationAxis + '; speed: ' + this.state.currentContent.rotationSpeed + ';'}
                        id={'synthesizer'} synth={'sounds: ' + this.state.currentContent.soundURL} class={'cantap'}
                        visible={'false'} model-loading={"unhide: synthesizer;"}
                    >
                    </a-entity>
                </a-entity>
                {this.state.currentContent.text &&
                    <a-entity geometry={"primitive: plane; width: " + this.state.currentContent.textWidth + "; height: auto"}
                        material={"color: #110a35"}
                        position={this.state.currentContent.position.x + ' ' + (this.state.currentContent.position.y + this.state.currentContent.textOffset) + ' 0.0015'}
                        text={'value:' + this.state.currentContent.text + '; color: white; width: ' + (this.state.currentContent.textWidth - 0.08) + '; height: auto; align: right;'}>
                    </a-entity>
                }

                <a-image
                    id={'loading-spinner'}
                    src={"#load-btn"} width={'0.2'} height={"0.2"}
                    position={this.state.currentContent.position.x + ' ' + this.state.currentContent.position.y + ' 0.003'}
                    visible={'true'}>
                </a-image>
            </a-entity>
        )
    }
}
export default Synthesizer;
